import {Component, OnInit} from '@angular/core';
import JobListing from '../domain/JobListing';
import {select, Store} from '@ngrx/store';
import {getSelectedJobListing, JobListingState} from '../store';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-create-job-listing',
  templateUrl: './create-job-listing.component.html',
  styleUrls: ['./create-job-listing.component.scss']
})
export class CreateJobListingComponent implements OnInit {

  selected: JobListing;
  private destroyed$ = new Subject<boolean>();
  preview = true;

  constructor(public jobListingStore: Store<JobListingState>) {
    jobListingStore
      .pipe(select(getSelectedJobListing))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((storeElement) => {
        if (storeElement) {
          // only updates through reassignment of variable
          this.preview = true;
          this.selected = storeElement;
        } else {
          this.selected = null;
          this.preview = false;
        }
      });
  }

  ngOnInit(): void {
  }

  changePreview(): void {
    this.preview = false;
  }
}
