import * as JobListingStoreActions from './job-listings.actions';
import {createSelector} from '@ngrx/store';
import JobListing from '../../domain/JobListing';


export {
  JobListingStoreModule
} from './job-listing-store.module';

export {
  JobListingStoreActions
};

export interface JobListingState {
  jobListings: JobListing[];
  selectedJobListing: JobListing;
}

export const selectState = (state: any) => {
  return state.jobListing;
};

export const selectJobListings = createSelector(
  selectState,
  (store: JobListingState) => {
    return store.jobListings;
  }
);

export const getJobListings = createSelector(
  selectState,
  (store: JobListingState) => {
    return store.jobListings;
  }
);

export const getSelectedJobListing = createSelector(
  selectState,
  (store: JobListingState) => {
    return store.selectedJobListing;
  }
);

