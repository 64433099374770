<div class="container-fluid p-0 w-100">
  <div class="row">
    <div class="col-12">
      <div class="card w-100 mb-3">
        <div class="card-body w-100 apex-chart-card">
          <div class="container-fluid p-0">
            <div class="row">
              <apx-chart
                [series]="chartOptions.series"
                [chart]="chartOptions.chart"
                [xaxis]="chartOptions.xaxis"
                [stroke]="chartOptions.stroke"
                [colors]="chartOptions.colors"
                [dataLabels]="chartOptions.dataLabels"
                [legend]="chartOptions.legend"
                [markers]="chartOptions.markers"
                [grid]="chartOptions.grid"
                [yaxis]="chartOptions.yaxis"
                [title]="chartOptions.title"
              ></apx-chart>
            </div>
          </div>
        </div>
      </div>
      <div class="card w-100">
        <div class="card-body">
          <div class="container-fluid filter-container">
            <div class="row query-button-row">
              <div class="col-2">
                <button mat-stroked-button type="submit" class="query-button w-100"
                        (click)="query()">
                  Query
                </button>
              </div>
            </div>
            <div>
              <div class="row mt-3">
                <div class="col-11">
                  <div class="row">
                    <div class="col-3 form-group" [formGroup]="formGroup">
                      <mat-form-field appearance="outline" class="seperated-form-field">
                        <mat-label>Start Date</mat-label>
                        <input matInput [matDatepicker]="startDP" [formControl]="startDate">
                        <mat-datepicker-toggle matSuffix [for]="startDP"></mat-datepicker-toggle>
                        <mat-datepicker #startDP></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="col-3 form-group" [formGroup]="formGroup">
                      <mat-form-field appearance="outline" class="seperated-form-field">
                        <mat-label>End Date</mat-label>
                        <input matInput [matDatepicker]="endDP" [formControl]="endDate">
                        <mat-datepicker-toggle matSuffix [for]="endDP"></mat-datepicker-toggle>
                        <mat-datepicker #endDP></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="col-3 form-group" [formGroup]="formGroup">
                      <mat-form-field appearance="outline" class="seperated-form-field">
                        <mat-label>Min Compensation</mat-label>
                        <input matInput type="number" [formControl]="minCompensationForm">
                      </mat-form-field>
                    </div>
                    <div class="col-3 form-group" [formGroup]="formGroup">
                      <mat-form-field appearance="outline" class="seperated-form-field">
                        <mat-label>Max Compensation</mat-label>
                        <input matInput type="number" [formControl]="maxCompensationForm">
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-3 form-group" [formGroup]="formGroup">
                      <mat-form-field appearance="outline" class="seperated-form-field">
                        <mat-label>Experience</mat-label>
                        <mat-select [formControl]="selectedExperienceForm" multiple>
                          <mat-option *ngFor="let experience of experiences" [value]="experience">
                            {{experience | translate}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-3 form-group" [formGroup]="formGroup">
                      <mat-form-field appearance="outline" class="seperated-form-field">
                        <mat-label>Occupation</mat-label>
                        <mat-select [formControl]="selectedOccupationForm" multiple>
                          <mat-option *ngFor="let occupation of occupations" [value]="occupation">
                            {{occupation | translate}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-3 form-group" [formGroup]="formGroup">
                      <mat-form-field appearance="outline" class="seperated-form-field">
                        <mat-label>Shift</mat-label>
                        <mat-select [formControl]="selectedShiftForm" multiple>
                          <mat-option *ngFor="let shift of shifts" [value]="shift">
                            {{shift | translate}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-6 form-group" [formGroup]="formGroup">
                      <mat-form-field appearance="outline" class="seperated-form-field">
                        <mat-label>Location</mat-label>
                        <input [placeholder]="placeholder" #inputField matInput [formControl]="location">
                      </mat-form-field>
                    </div>
                    <div class="col-3 form-group" [formGroup]="formGroup">
                      <mat-form-field appearance="outline" class="seperated-form-field">
                        <mat-label>Radius</mat-label>
                        <mat-select [formControl]="selectedRadiusForm">
                          <mat-option *ngFor="let radius of radiuses" [value]="radius">
                            {{radius}} KM
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-3 form-group" [formGroup]="formGroup">
                      <mat-form-field appearance="outline" class="seperated-form-field">
                        <mat-label>Location Type</mat-label>
                        <mat-select [formControl]="selectedLocationType">
                          <mat-option *ngFor="let locationType of locationTypes" [value]="locationType">
                            {{locationType | translate}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

