<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <!--      TODO make header component instead-->
      <h1 class="page-title">Add new job listing</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <div class="card w-100">
        <div class="card-body py-4">
          <app-create-job-listing-card *ngIf="!preview" [selectedJobListing]="selected"></app-create-job-listing-card>
          <app-job-listing-card *ngIf="preview" [jobListing]="selected" (jobListingToCopy)="changePreview()"></app-job-listing-card>
        </div>
      </div>
    </div>
    <div class="col-8">
      <div class="card w-100 h-100">
        <div class="card-body py-4 h-100">
          <app-job-listing-list-card></app-job-listing-list-card>
        </div>
      </div>
    </div>
  </div>
</div>
