import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import JobListing from '../domain/JobListing';
import {SelectionType} from '@swimlane/ngx-datatable';
import {select, Store} from '@ngrx/store';
import {getJobListings, JobListingState} from '../store';
import {getJobListingsStart, sendJobOfferStart} from '../store/job-listing-store/job-listings.actions';
import {takeUntil} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {EmployeeService} from '../services/employee.service';
import {AppUser} from "../domain/AppUser";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";

@Component({
  selector: 'app-candidate-employees',
  templateUrl: './candidate-employees.component.html',
  styleUrls: ['./candidate-employees.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CandidateEmployeesComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort, {static: false}) set sort(sort: MatSort) {
    if (sort && this.dataSource) { // initially setter gets called with undefined
       this.dataSource.sort = sort;
    }
  }

  private destroyed$ = new Subject<boolean>();
  private destroyedEmployee$: Subscription;

  rows = [];
  filteredRows = [];

  existingJobListings: Array<JobListing> = [];

  dataSource: MatTableDataSource<AppUser>;
  columns = [{prop: 'name'}, {prop: 'compensation'}, {prop: 'certifications'}];

  columnsToDisplay = [
    {prop: 'name', name: 'Name'},
    {prop: 'compensation', name: 'Compensation'},
    {prop: 'certifications', name: 'Certifications'},
    {prop: 'experience', name: 'Experience'},
  ];
  expandedElement: AppUser | null;
  columnsToDisplayWithExpand = [...this.columnsToDisplay.map(column => column.prop), 'invite', 'expand'];


  limitOptions = [10, 25, 50, 100];
  limit = 25;

  private _selectedJobListing: any;
  selectionType: SelectionType = SelectionType.single;

  constructor(private employeeService: EmployeeService, public jobListingStore: Store<JobListingState>) {
    this.jobListingStore.dispatch(getJobListingsStart({companyId: 'ORG#123', hrRepId: ''}));

    this.jobListingStore
      .pipe(select(getJobListings))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((jobListings) => {
        this.existingJobListings = [...jobListings];
      });

    this.destroyedEmployee$ = this.employeeService
      .getEmployees()
      .subscribe(users => {
        this.dataSource = new MatTableDataSource(users);
      });
  }

  ngOnInit(): void {
  }

  get selectedJobListing(): any {
    return this._selectedJobListing;
  }

  set selectedJobListing(value: any) {
    console.log('selectedJobListing', value)
    this._selectedJobListing = value;
    this.sortRows(value.title);
  }

  sortRows(jobListingTitle: any): void {
    if (jobListingTitle) {
      this.filteredRows = this.rows.filter((result) => result.occupation === jobListingTitle);
    }
  }

  onActivate($event: any) {

  }

  onSelect($event: any) {

  }

  sendOffer($event, row) {
    $event.stopPropagation();
    console.log('$event', $event, 'value', row);
    this.jobListingStore.dispatch(sendJobOfferStart({jobListing: this.selectedJobListing, user: row}));
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.destroyedEmployee$.unsubscribe()
  }
}
