import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import JobListing from '../../domain/JobListing';
import {selectJobListing} from '../../store/job-listing-store/job-listings.actions';
import {Store} from '@ngrx/store';
import {JobListingState} from '../../store';

@Component({
  selector: 'app-job-listing-card',
  templateUrl: './job-listing-card.component.html',
  styleUrls: ['./job-listing-card.component.scss']
})
export class JobListingCardComponent implements OnInit {

  @Input() jobListing!: JobListing;
  @Output() jobListingToCopy: EventEmitter<JobListing> = new EventEmitter<JobListing>();

  constructor(public jobListingStore: Store<JobListingState>) {
  }

  ngOnInit(): void {
  }

  createNew() {
    this.jobListingStore.dispatch(selectJobListing({jobListing: null}));
  }

  copy() {
    this.jobListingToCopy.emit(this.jobListing);
    this.jobListingStore.dispatch(selectJobListing({jobListing: this.jobListing}));
  }
}
