import {Component, OnInit} from '@angular/core';
import {ApexAxisChartSeries, ApexChart, ApexPlotOptions, ApexTitleSubtitle, ApexXAxis, ApexYAxis} from 'ng-apexcharts';
import {Router} from '@angular/router';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  plotOptions: ApexPlotOptions;
};

export class PlaceSearchResult {
  constructor(address : string, location: google.maps.LatLng | undefined) {
    this.address = address;
    this.latlng = location;
  }

  address: string;
  latlng?: google.maps.LatLng;

  public toString() : string {
    return this.address;
  }
}
@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})

export class AnalyticsComponent implements OnInit {

  analyticCharts: { urlName: string, analyticChart: string }[] = [
    {
      urlName: 'requestedCompensation',
      analyticChart: 'Requested Compensation'
    },
    {
      urlName: 'acceptedCompensation',
      analyticChart: 'Accepted Compensation'
    },
    {
      urlName: 'populationRankings',
      analyticChart: 'Demographics'
    }
  ];

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    // this.loadComponent('employeeSalaries');
  }

  isCurrentChart(id: string) {
    return this.router.url.includes(id);
  }

}
