<div class="container-fluid p-0">
  <div class="row">
    <div class="col-md-4 col-xl-3">
      <div>
        <h1 class="page-title">Job Listings</h1>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <app-job-listing-selector (selectedJobListingChange)="selectedJobListing = $event">
      </app-job-listing-selector>
    </div>
    <div class="col-8">
      <div class="card w-100">
        <div class="card-body py-4">
          <div class="container-fluid">
            <div class="row justify-content-between">
              <div class="col-2">
                <h5 class="card-title mb-2">Limit</h5>
              </div>
            </div>
            <div class="row justify-content-between">
              <div class="col-2 mb-3">
                <ng-select
                  [clearable]="false"
                  placeholder="limit"
                  class="custom"
                  [items]="limitOptions"
                  [(ngModel)]="limit">
                </ng-select>
              </div>
            </div>
            <div class="row">
              <div class="col-12" *ngIf="selectedJobListing">
                <table mat-table matSort
                       [dataSource]="dataSource" multiTemplateDataRows
                       class="mat-elevation-z0">
                  <ng-container matColumnDef="{{column.prop}}" *ngFor="let column of columnsToDisplay">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{column.name}} </th>
                    <ng-container [ngSwitch]="column.prop">
                      <ng-container *ngSwitchCase="'certifications'">
                        <td mat-cell *matCellDef="let element">
                          <mat-chip-list>
                            <mat-chip *ngFor="let cert of element[column.prop]">{{cert}}</mat-chip>
                          </mat-chip-list>
                        </td>
                      </ng-container>
                      <ng-container *ngSwitchCase="'compensation'">
                        <td mat-cell *matCellDef="let element"> {{element[column.prop] | currency}} </td>
                      </ng-container>
                      <ng-container *ngSwitchDefault>
                        <td mat-cell *matCellDef="let element"> {{element[column.prop] | translate}} </td>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container matColumnDef="invite">
                    <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
                    <td mat-cell *matCellDef="let element" class="header-align-right">
                      <button mat-stroked-button class="invite-button"
                              (click)="sendOffer($event, element);$event.stopPropagation()">
                        Send Offer
                      </button>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="expand">
                    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                    <td mat-cell *matCellDef="let element" class="header-align-right">
                      <button mat-icon-button aria-label="expand row"
                              (click)="(expandedElement = expandedElement === element ? null : element);
                      $event.stopPropagation()">
                        <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                        <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                      </button>
                    </td>
                  </ng-container>

                  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                  <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                      <div class="example-element-detail"
                           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <!--                  <mat-card class="job-listing-description mat-elevation-z0 p-0">-->
                        <!--                    <mat-card-content>{{element.description}}</mat-card-content>-->
                        <!--                    <mat-card-actions>-->
                        <div class="example-element-description">{{element.description}}</div>
                        <div class="example-element-description">
                          <mat-chip-list>
                            <mat-chip *ngFor="let cert of element['qualCertsList']">{{cert}}</mat-chip>
                          </mat-chip-list>
                        </div>
                        <!--                    </mat-card-actions>-->
                        <!--                  </mat-card>-->
                        <!--TODO: possible add questions        -->
                        <!-- <div class="example-element-weight"> {{element.questions}} </div>-->
                      </div>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
                  <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
                      class="example-element-row"
                      [class.example-expanded-row]="expandedElement === element"
                      (click)="expandedElement = expandedElement === element ? null : element">
                  </tr>
                  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

