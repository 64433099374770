import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output} from '@angular/core';
import {LyDialog} from '@alyle/ui/dialog';
import {ImgCropperEvent} from '@alyle/ui/image-cropper';

import {CropperDialog} from './cropper-dialog';
import {faImage} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-cropper-with-dialog',
  templateUrl: './cropper-with-dialog.component.html',
  styleUrls: ['./cropper-with-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CropperWithDialogComponent {
  cropped?: string;
  constructor(
    public _dialog: LyDialog,
    private _cd: ChangeDetectorRef
  ) { }

  fileImageIcon = faImage;

  // TODO: do with stream instead maybe
  @Output('cropped')
  emitter: EventEmitter<any> = new EventEmitter<any>();

  openCropperDialog(event: Event) {
    this.cropped = null!;
    this._dialog.open<CropperDialog, Event>(CropperDialog, {
      data: event,
      width: 700,
      disableClose: false,
    }).afterClosed.subscribe((result?: ImgCropperEvent) => {
      if (result) {
        this.cropped = result.dataURL;
        this._cd.markForCheck();
        this.emitter.emit(result.dataURL);
      }
    });
  }
}
