export default class Company {
  id: string;
  sk: string;
  name: string;
  imageUrl: string;
  websiteUrl: string;

  constructor(id: string, sk: string, name: string, imageUrl: string, websiteUrl: string) {
    this.id = id;
    this.sk = sk;
    this.name = name;
    this.imageUrl = imageUrl;
    this.websiteUrl = websiteUrl;
  }
}
