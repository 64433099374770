
import {createAction, props} from '@ngrx/store';
import JobListing from '../../domain/JobListing';
import {Action} from "rxjs/internal/scheduler/Action";
import AvaeleUser from "../../domain/AvaeleUser";
import JobOffer from "../../domain/JobOffer";


export enum ActionTypes {
  ADD_JOB_LISTING_START = '[Job Listings] Add Job Listing Start',
  ADD_JOB_LISTING_SUCCESS = '[Job Listings] Add Job Listing Success',
  DELETE_JOB_LISTING = '[Job Listings] Delete Job Listing',
  GET_JOB_LISTINGS_START = '[Job Listings] Get Job Listings Start',
  GET_JOB_LISTINGS_SUCCESS = '[Job Listings] Get Job Listings Success',
  SELECT_JOB_LISTING = '[Job Listings] Select Job Listing',
  SEND_OFFER_START = '[Job Listings] Send Offer Start',
  SEND_OFFER_SUCCESS = '[Job Listings] Send Offer Success',
  GET_JOB_OFFERS_FROM_JOB_LISTING_START = '[Job Listings] Get Job Offers From Job Listing Start',
  GET_JOB_OFFERS_FROM_JOB_LISTING_SUCCESS = '[Job Listings] Get Job Offers From Job Listing Success',
}

export const addJobListingStart = createAction(
  ActionTypes.ADD_JOB_LISTING_START,
  props<{jobListing: JobListing}>()
);

export const addJobListingSuccess = createAction(
  ActionTypes.ADD_JOB_LISTING_SUCCESS,
  // FIXME: figure out how to change to none or void
  props<any>()
);

export const deleteJobListing = createAction(
  ActionTypes.DELETE_JOB_LISTING,
  props<{jobListingId: string}>()
);

export const getJobListingsStart = createAction(
  ActionTypes.GET_JOB_LISTINGS_START,
  props<{companyId: string, hrRepId: string}>()
);

export const getJobListingsSuccess = createAction(
  ActionTypes.GET_JOB_LISTINGS_SUCCESS,
  props<{jobListings: Array<JobListing>}>()
);

export const selectJobListing = createAction(
  ActionTypes.SELECT_JOB_LISTING,
  props<{jobListing: JobListing}>()
);

export const sendJobOfferStart = createAction(
  ActionTypes.SEND_OFFER_START,
  props<{jobListing: JobListing, user: AvaeleUser}>()
);

export const sendJobOfferSuccess = createAction(
  ActionTypes.SEND_OFFER_SUCCESS,
  props<{offer: JobOffer}>()
);

export const sendGetJobOfferFromJobListingStart = createAction(
  ActionTypes.GET_JOB_OFFERS_FROM_JOB_LISTING_START,
  props<{jobListing: JobListing}>()
);

export const sendGetJobOfferFromJobListingSuccess = createAction(
  ActionTypes.GET_JOB_OFFERS_FROM_JOB_LISTING_SUCCESS,
  props<{ jobOffers: Array<JobOffer>}>()
);




