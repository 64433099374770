import {Experience} from '../Experience';
import {Occupation} from '../Occupation';
import {Shift} from '../Shift';
import AnalyticRequest from './AnalyticRequest';
import AnalyticsLocationFilter from './AnalyticsLocationFilter';
import {LocationType} from '../LocationType';

export default class CompensationAnalyticRequest extends AnalyticRequest {
  minCompensation: number;
  maxCompensation: number;


  constructor(startDate: string, endDate: string, experienceList: Array<Experience>, occupationList: Array<Occupation>, shiftList: Array<Shift>, locationFilter: AnalyticsLocationFilter, locationType: LocationType, minCompensation: number, maxCompensation: number) {
    super(startDate, endDate, experienceList, occupationList, shiftList, locationFilter, locationType);
    this.minCompensation = minCompensation;
    this.maxCompensation = maxCompensation;
  }
}
