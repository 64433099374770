<div #container style="height: 100%">
  <div class="container-fluid">
    <div #controlPanel class="row justify-content-between">
      <div class="col-2 mb-3">
        <ng-select
          [clearable]="false"
          placeholder="Select by Job Listing"
          class="dark"
          [items]="occupations"
          bindLabel="title"
          [(ngModel)]="selectedOccupation">
        </ng-select>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-card class="mat-elevation-z0 card-border">
          <table mat-table
                 [dataSource]="dataSource" multiTemplateDataRows
                 class="mat-elevation-z0">
            <ng-container matColumnDef="{{column.prop}}" *ngFor="let column of columnsToDisplay">
              <th mat-header-cell *matHeaderCellDef> {{column.name}} </th>
              <ng-container [ngSwitch]="column.prop">
                <ng-container *ngSwitchCase="'employeeStartDate'">
                  <td mat-cell *matCellDef="let element"> {{(element[column.prop] | translate).substring(0, 10)}} </td>
                </ng-container>
                <ng-container *ngSwitchCase="'jobListingStartDate'">
                  <td mat-cell *matCellDef="let element"> {{(element[column.prop] | translate).substring(0, 10)}} </td>
                </ng-container>
                <ng-container *ngSwitchCase="'jobListingEndDate'">
                  <td mat-cell *matCellDef="let element"> {{(element[column.prop] | translate).substring(0, 10)}} </td>
                </ng-container>
                <ng-container *ngSwitchCase="'jobListingState'">
                  <td mat-cell *matCellDef="let element">
                    <mat-chip>{{element[column.prop] | translate}}</mat-chip>
                  </td>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <td mat-cell *matCellDef="let element"> {{element[column.prop] | translate}} </td>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container matColumnDef="duplicate">
              <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
              <td mat-cell *matCellDef="let element" class="header-align-right">
                <button mat-icon-button
                        (click)="$event.stopPropagation()">
                  <mat-icon>content_copy</mat-icon>
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
              <td mat-cell *matCellDef="let element" class="header-align-right">
                <button mat-icon-button
                        (click)="$event.stopPropagation()">
                  <mat-icon>delete_forever</mat-icon>
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="expand">
              <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
              <td mat-cell *matCellDef="let element" class="header-align-right">
                <button mat-icon-button aria-label="expand row"
                        (click)="(expandedElement = expandedElement === element ? null : element);
                      $event.stopPropagation()">
                  <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                  <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                </button>
              </td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                <div class="example-element-detail"
                     [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
<!--                  <mat-card class="job-listing-description mat-elevation-z0 p-0">-->
<!--                    <mat-card-content>{{element.description}}</mat-card-content>-->
<!--                    <mat-card-actions>-->
                  <div class="example-element-description">
                    <h4>Description:</h4>
                    {{element.description}}
                  </div>
                  <div class="example-element-description" *ngIf="element['questions'] != null">
                    <h4>Questions:</h4>
                    <p *ngFor="let question of element['questions']; let i = index">{{i + 1}}: {{question.question}}</p>
                  </div>
                  <div class="example-element-description">
                    <mat-chip-list>
                      <mat-chip *ngFor="let cert of element['qualCertsList']">{{cert}}</mat-chip>
                    </mat-chip-list>
                  </div>
<!--                    </mat-card-actions>-->
<!--                  </mat-card>-->
                  <!--TODO: possible add questions        -->
                  <!-- <div class="example-element-weight"> {{element.questions}} </div>-->
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
                class="example-element-row"
                [class.example-expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
          </table>
        </mat-card>
      </div>
    </div>
  </div>
</div>
