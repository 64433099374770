import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Auth} from 'aws-amplify';
import {getSignedInUserStart} from '../../store/session-store/session.actions';
import {Store} from '@ngrx/store';
import {SessionState} from '../../store/session-store';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AdminService} from '../../services/admin.service';
import {AvaeleUserStatus} from '../../domain/AvaeleUserStatus';
import {DialogService} from '../../services/dialog.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password-complete.component.html',
  styleUrls: ['./forgot-password-complete.component.scss']
})
export class ForgotPasswordCompleteComponent implements OnInit {

  password: string;
  passwordConfirm: string;
  email: string;
  recoveryCode: string;

  recoveryCodeForm: FormControl = new FormControl('', [Validators.required]);
  passwordForm: FormControl = new FormControl('', [Validators.required]);
  passwordConfirmForm: FormControl = new FormControl('', [Validators.required]);
  formGroup: FormGroup;
  hidePassword: boolean = true;
  hidePasswordConfirm: boolean = true;
  constructor(private router: Router,
              private route: ActivatedRoute,
              public sessionStore: Store<SessionState>,
              public adminService: AdminService,
              public dialogService: DialogService) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe((params) => {
      this.email = atob(params.userName);
    });

    this.formGroup = new FormGroup({
      recoveryCode: this.recoveryCodeForm,
      Password: this.passwordForm,
      passwordConfirm: this.passwordConfirmForm
    });
  }

  changePassword($event?: any) {
    if ($event == null || $event.keyCode == 13) {
      if (this.password === this.passwordConfirm) {
        try {
          Auth.forgotPasswordSubmit(this.email, this.recoveryCode, this.password).then(r => {
            this.router.navigate(['/']);
          });
        } catch (e) {
          this.dialogService.error('Error', 'Could not change password');
          console.log('error changing password:', e);
        }
      } else {
        console.log('Passwords must match!');
      }
    }
  }
}
