<div class="card w-100">
  <div class="card-body py-4">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="outline">
            <mat-icon matPrefix>search</mat-icon>
            <input matInput>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-selectable-card-group (selectedChange)="selectedJobListing = $event">
            <app-selectable-card class="mb-3" *ngFor="let jobListing of existingJobListings" [value]="jobListing">
              <div class="w-100 container-fluid p-3">
                <div title class="col-12 underline-hover">
                  <h2><strong>{{jobListing.occupation | translate}}</strong></h2>
                </div>
                <div class="row">
                  <div class="col-12">
                    {{jobListing.jobType ? jobListing.jobType : 'FULL_TIME' | translate}}
                    / {{jobListing.shift | translate}}
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-12">
                    {{jobListing.address.city ? jobListing.address.city : 'Toronto'| translate}}
                    , {{jobListing.address.province ? jobListing.address.province : 'Ontario' | translate}}
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    {{jobListing.description | translate}}
                  </div>
                </div>
              </div>
            </app-selectable-card>
          </app-selectable-card-group>
        </div>
      </div>
    </div>
  </div>
</div>
