import {createReducer, on} from '@ngrx/store';
import * as JobListingAction from './job-listings.actions';
import JobListing from '../../domain/JobListing';
import {JobListingState} from './index';
import JobOffer from "../../domain/JobOffer";
import AvaeleUser from "../../domain/AvaeleUser";

export const jobListingFeatureKey = 'jobListing';

export const initialState: JobListingState = {
  selectedJobListing: undefined,
  jobListings: new Array<JobListing>()
};

export const jobListingReducer = createReducer(
  initialState,
  on(JobListingAction.addJobListingStart, (state: JobListingState, jobListing: { jobListing: JobListing }): JobListingState => {
    const list = [...state.jobListings];
    list.push(jobListing.jobListing);
    return {
      jobListings: list,
      selectedJobListing: state.selectedJobListing
    };
  }),
  on(JobListingAction.addJobListingSuccess, (state: JobListingState, result: { response: any }): JobListingState => {
    return state;
  }),
  on(JobListingAction.deleteJobListing, (state: JobListingState, jobListingId: { jobListingId: string }): JobListingState => {
    return state[0];
  }),
  on(JobListingAction.getJobListingsStart, (state: JobListingState, query: { companyId: string, hrRepId: string }): JobListingState => {
    return state;
  }),
  on(JobListingAction.getJobListingsSuccess, (state: JobListingState, props: { jobListings: Array<JobListing> }): JobListingState => {
    state = {
      jobListings: props.jobListings,
      selectedJobListing: state.selectedJobListing
    };
    return state;
  }),
  on(JobListingAction.selectJobListing, (state: JobListingState, props: { jobListing: JobListing }): JobListingState => {
    state = {
      jobListings: state.jobListings,
      selectedJobListing: props.jobListing
    };
    return state;
  }),
  on(JobListingAction.sendJobOfferStart, (state: JobListingState, props: { jobListing: JobListing, user: AvaeleUser }): JobListingState => {
    return state;
  }),
  on(JobListingAction.sendJobOfferSuccess, (state: JobListingState, props: { offer: JobOffer }): JobListingState => {
      return state;
    }
  )
);
