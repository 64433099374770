import {Url} from 'url';
import {Occupation} from './Occupation';

export default class Employee {
  id: string; // May change later
  firstName: string;
  lastName: string;
  email: string;
  industry: 'oil' | 'gas';
  occupation: Occupation;
  location: { longitude: number, latitude: number };
  avatarUrl: string;
  experienceInYears: number;
  linkedlnUrl: string;
  instagramUrl: string;
  facebookUrl: string;
  twitterUrl: string;
  currentJobName: string;
  currentJobLocation: string;

}
