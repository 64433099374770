import {Component, OnInit} from '@angular/core';
import {Auth} from 'aws-amplify';
import {Store} from '@ngrx/store';
import {SessionState} from '../../store/session-store';
import {ActivatedRoute, Router} from '@angular/router';
import {getSignedInUserStart} from '../../store/session-store/session.actions';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DialogService} from '../../services/dialog.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  isLoading = false;
  emailAddress = 'ellenbogensholom@gmail.com';
  password = 'Password1!';
  emailForm: FormControl = new FormControl('', [Validators.required, Validators.pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')]);
  passwordForm: FormControl = new FormControl('', [Validators.required]);
  formGroup: FormGroup;
  hidePassword: boolean = true;
  constructor(private router: Router,
              public sessionStore: Store<SessionState>,
              private route: ActivatedRoute,
              public dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      Email: this.emailForm,
      Password: this.passwordForm
    });
  }

  onSignIn($event?: any) {
    try {
      if ($event == null || $event.keyCode == 13) {
        Auth.signIn(this.emailAddress, this.password).then(
          user => {
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            this.router.navigate(['changePassword'], {queryParams: {userName: btoa(this.emailAddress)}});
          } else {
            const pk = user.attributes['custom:company_id'];
            const userType = user.attributes['custom:user_type'];
            const sk = user.attributes['email'];
            this.sessionStore.dispatch(getSignedInUserStart({pk, sk, userType}));
            this.router.navigate(['/']);
          }
        },
          err => this.dialogService.error('Error', 'Could not sign in')
        );
      }
    } catch (error) {
      this.dialogService.error('Error', 'Could not sign in');
      console.log('error signing up:', error);
    }
  }

  forgotPassword() {
    this.router.navigate(['forgotPasswordStart']);
  }
}
