<div class="container-fluid p-0">
  <div class="row">
    <div class="col-md-4 col-xl-3">
      <div class="card mb-3">
        <div class="card-header">
          <h1 class="card-title mb-0">Profile</h1>
        </div>
        <div class="card-body text-center">
          <img [src]="employee.avatarUrl" width="'64'" height="'64'" class="img-fluid rounded-circle mb-2"/>
          <h5 class="card-title mb-0">{{employee.firstName}} {{employee.lastName}}</h5>
          <div class="text-muted">{{employee.occupation}}</div>
        </div>
        <hr class="my-0"/>
        <div class="card-body">
          <h1 class="card-title mb-0">About</h1>
          <ul class="list-unstyled mb-1 mt-2">
            <li class="mb-1">
              <fa-icon [icon]="faHome" [styles]="{'stroke': '#a9acb3', 'color': '#a9acb3'}"
                       class="me-3 fa-fw"></fa-icon>
              <span class="text-color">Home</span>
            </li>
            <li class="mb-1">
              <fa-icon [icon]="faGlobe" [styles]="{'stroke': '#a9acb3', 'color': '#a9acb3'}"
                       class="me-3 fa-fw"></fa-icon>
              <span class="text-color">Canada</span>
            </li>
          </ul>
        </div>
        <hr class="my-0"/>
        <div class="card-body">
          <h1 class="card-title mb-0">Social Media</h1>
          <ul class="list-unstyled mt-2 mb-1">
            <li class="mb-1" *ngIf="employee.facebookUrl">
              <fa-icon [icon]="faFaceBook" [styles]="{'stroke': '#a9acb3', 'color': '#a9acb3'}"
                       class="me-3 fa-fw"></fa-icon>
              <a [href]="employee.facebookUrl">Facebook</a>
            </li>
            <li class="mb-1" *ngIf="employee.twitterUrl">
              <fa-icon [icon]="faTwitter" [styles]="{'stroke': '#a9acb3', 'color': '#a9acb3'}"
                       class="me-3 fa-fw"></fa-icon>
              <a [href]="employee.twitterUrl">Twitter</a>
            </li>
            <li class="mb-1" *ngIf="employee.instagramUrl">
              <fa-icon [icon]="faInstagram" [styles]="{'stroke': '#a9acb3', 'color': '#a9acb3'}"
                       class="me-3 fa-fw"></fa-icon>
              <a [href]="employee.instagramUrl">Instagram</a>
            </li>
            <li class="mb-1" *ngIf="employee.linkedlnUrl">
              <fa-icon [icon]="faLinkedin" [styles]="{'stroke': '#a9acb3', 'color': '#a9acb3'}"
                       class="me-3 fa-fw"></fa-icon>
              <a [href]="employee.linkedlnUrl">Linkedln</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
