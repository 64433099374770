  <div class="wrapper card w-100 container-fluid p-3"
       [ngClass]="selected ? 'card-selected' : 'card-unselected'" (click)="toggle()">
    <div>
      <div>
        <ng-content select="[title]"></ng-content>
      </div>
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col-9">
            <ng-content></ng-content>
          </div>
          <div class="col-3 selectable-checkbox">
            <mat-checkbox class="mat-checkbox-custom" [checked]="selected" (click)="$event.preventDefault()"></mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
