import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromJobListingReducer from './job-listings.reducer';
import {EffectsModule} from '@ngrx/effects';
import JobListingEffects from './job-listing.effects';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(fromJobListingReducer.jobListingFeatureKey,  fromJobListingReducer.jobListingReducer),
        EffectsModule.forFeature([JobListingEffects])
    ],
    providers: []
})
export class JobListingStoreModule {
}
