<div class="container-fluid w-25 mt-5">
  <div class="row card">
    <div class="col-12 card-body">
      <div class="container-fluid w-100">
        <div class="row mb-5">
          <div class="col-12 d-flex">
            <h1 class="page-title">CHANGE PASSWORD</h1>
          </div>
        </div>
        <div class="col-12">
          <div class="row mb-4">
            <div class="col-12" [formGroup]="formGroup" (keyup)="changePassword($event)">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Temporary Password</mat-label>
                <input matInput
                       [formControl]="tmpPasswordForm"
                       name="tmpPassword"
                       [type]="hideTmpPassword ? 'password' : 'text'"
                       minlength="8"
                       [(ngModel)]="tmpPassword"
                       placeholder="Enter Temporary Password" required>
                <mat-icon matSuffix (click)="hideTmpPassword = !hideTmpPassword">{{hideTmpPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12" [formGroup]="formGroup" (keyup)="changePassword($event)">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>New Password</mat-label>
                <input matInput
                       [formControl]="passwordForm"
                       name="password"
                       [type]="hidePassword ? 'password' : 'text'"
                       minlength="8"
                       [(ngModel)]="password"
                       placeholder="Enter New Password" required>
                <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
              </mat-form-field>
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-12" [formGroup]="formGroup" (keyup)="changePassword($event)">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Confirm New Password</mat-label>
                <input matInput
                       [formControl]="passwordConfirmForm"
                       name="passwordConfirm"
                       [type]="hidePasswordConfirm ? 'password' : 'text'"
                       minlength="8"
                       [(ngModel)]="passwordConfirm"
                       placeholder="Confirm New Password" required>
                <mat-icon matSuffix (click)="hidePasswordConfirm = !hidePasswordConfirm">{{hidePasswordConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <button mat-stroked-button class="submit-button w-100" type="submit" (click)="changePassword()">Change
              Password
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
