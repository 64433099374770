import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appAutoTab]'
})
export class AutoTabDirective {
  @Input('appAutoTab') appAutoTab;


  @HostListener('input', ['$event']) onInput(input): void{
    const target = input.target;
    const length = target.value.length;
    const maxLength = target.attributes.maxlength.value;
    if (length >= maxLength) {
      target.value = input.data;
      if (this.appAutoTab !== input) {
        this.appAutoTab.focus();
      }
    }
  }
}
