import {createAction, props} from '@ngrx/store';
import AvaeleUser from '../../domain/AvaeleUser';
import Company from '../../domain/Company';


export enum ActionTypes {
  GET_HR_REPS_START = '[Session] Get Hr Reps Start',
  GET_HR_REPS_SUCCESS = '[Session] Get Hr Reps Success',
  GET_SIGNED_IN_USER_START = '[Session] Get Signed In User Start',
  GET_SIGNED_IN_USER_SUCCESS = '[Session] Get Signed In User SUCCESS',
  SET_SIGNED_IN_DATA = '[Session] Set Signed In Data',
}

export const getHrRepsStart = createAction(
  ActionTypes.GET_HR_REPS_START,
  props<{foo: any}>()
);

export const getHrRepsSuccess = createAction(
  ActionTypes.GET_HR_REPS_SUCCESS,
  props<{hrReps: Array<any>}>()
);

export const getSignedInUserStart = createAction(
  ActionTypes.GET_SIGNED_IN_USER_START,
  props<{pk: string, sk: string, userType: string}>()
);

export const getSignedInUserSuccess = createAction(
  ActionTypes.GET_SIGNED_IN_USER_SUCCESS,
  props<{user: any}>()
);

export const setSignedInData = createAction(
  ActionTypes.SET_SIGNED_IN_DATA,
  props<{user: AvaeleUser, company: Company}>()
);



