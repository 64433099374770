import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-selectable-card',
  templateUrl: './selectable-card.component.html',
  styleUrls: ['./selectable-card.component.scss']
})
export class SelectableCardComponent implements OnInit {
  @Input() selected = false;
  @Output() selectedChange = new EventEmitter<boolean>();

  @Input() value: any;

  constructor() { }

  ngOnInit(): void {
  }

  toggle(): void{
    this.selected = !this.selected;
    this.selectedChange.emit(this.selected);
  }

}
