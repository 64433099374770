import {Component, OnInit} from '@angular/core';
import {lyl, StyleRenderer, ThemeVariables} from '@alyle/ui';
import {Router} from '@angular/router';
import {Auth} from 'aws-amplify';

const STYLES = (theme: ThemeVariables) => ({
  $global: lyl`{
    body {
      background-color: ${theme.background.default}
      color: ${theme.text.default}
      font-family: ${theme.typography.fontFamily}
      margin: 0
      direction: ${theme.direction}
    }
  }`,
  root: lyl`{
    display: block
  }`
});

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent {

  private sideBarWidth = '250px';

  readonly classes = this.sRenderer.renderSheet(STYLES, true);

  links = [
    {title: 'Candidates', fragment: '/candidateEmployees'},
    {title: 'Job Listings', fragment: '/createJobListing'},
    {title: 'Offers', fragment: '/offers'},
    {title: 'Analytics', fragment: '/analytics', premium: true},
    {title: 'Console', fragment: '/console'}
  ];

  constructor(
    readonly sRenderer: StyleRenderer,
    private router: Router
  ) {
  }

  title = 'Avaele';
  openedMenu: boolean = true;

  toggleSideBar() {
    this.openedMenu = !this.openedMenu;
  }

  getSideBarWidth() {
    return this.openedMenu ? this.sideBarWidth : '0px';
  }


  getMainWidth() {
    return {
      marginLeft: this.getSideBarWidth(),
      width: `calc(100% - ${this.getSideBarWidth()})`
    };
  }

  logout() {
    Auth.signOut({global: true});
    this.router.navigate(['/signin']);
  }
}
