<div class="container-fluid p-0">
  <div class="row mb-3" *ngIf="bannerImg">
    <div class="col-12">
      <mat-card>
        <mat-card-title>Banner</mat-card-title>
        <img width="100%" class="mb-3" style="border-radius: 8px" [src]="bannerImg"/>
        <mat-card-actions>
          <div class="container-fluid p-0">
            <div class="row mb-3 justify-content-between" *ngIf="bannerImg">
              <div class="col-2">
                <app-cropper-with-dialog (cropped)="bannerImg = $event"></app-cropper-with-dialog>
              </div>
              <div class="col-2 d-flex justify-content-end">
                <button mat-stroked-button class="clear-job-button" (click)="bannerImg = null">Clear</button>
              </div>
            </div>
          </div>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12 form-group" [formGroup]="formGroup">
      <mat-card>
        <mat-card-title>Details</mat-card-title>
        <div class="container=fluid p-0">
          <div class="row mb-3">
            <div class="col-12">
              <mat-form-field appearance="outline">
                <mat-label>Select an Occupation</mat-label>
                <mat-select [formControl]="selectedOccupationForm" required>
                  <mat-option *ngFor="let occupation of occupations" [value]="occupation">
                    {{occupation | translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-9 form-group" [formGroup]="formGroup">
              <mat-form-field appearance="outline">
                <mat-label>Experience</mat-label>
                <mat-select [formControl]="selectedExperienceForm" required>
                  <mat-option *ngFor="let experience of experiences" [value]="experience">
                    {{experience | translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-6 form-group" [formGroup]="formGroup">
              <mat-form-field appearance="outline">
                <mat-label>Job Type</mat-label>
                <mat-select [formControl]="selectedJobTypeForm" required>
                  <mat-option *ngFor="let jobType of jobTypes" [value]="jobType">
                    {{jobType | translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-6 form-group" [formGroup]="formGroup">
              <mat-form-field appearance="outline">
                <mat-label>Shift</mat-label>
                <mat-select [formControl]="selectedShiftForm" required>
                  <mat-option *ngFor="let shift of shifts" [value]="shift">
                    {{shift | translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12 form-group" [formGroup]="formGroup">
              <mat-form-field appearance="outline">
                <mat-label>Openings</mat-label>
                <input matInput type="number" [formControl]="openingsForm">
              </mat-form-field>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12 form-group" [formGroup]="formGroup">
              <mat-form-field appearance="outline">
                <mat-chip-list #certsList required [formControl]="certsForm">
                  <mat-chip *ngFor="let cert of jobListing.qualCertsList" [removable]="removable"
                            (removed)="removeQualifications(cert)">
                    {{cert | translate}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                  </mat-chip>
                  <input
                    placeholder="Qualifications"
                    [matChipInputFor]="certsList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="addQualification($event)"
                    [matAutocomplete]="certsAutocomplete"
                    [formControl]="certInputForm"
                  />
                </mat-chip-list>
                <mat-autocomplete #certsAutocomplete="matAutocomplete" (optionSelected)="selectQualifications($event);">
                  <mat-option *ngFor="let cert of filteredCerts$ | async" [value]="cert">
                    {{cert | translate}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12 form-group" [formGroup]="formGroup">
              <mat-form-field appearance="outline">
                <mat-label>Description</mat-label>
                <textarea matInput [formControl]="descriptionForm"></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12 form-group" [formGroup]="formGroup">
      <mat-card>
        <mat-card-title>Date</mat-card-title>
        <div class="container=fluid p-0">
          <div class="row mb-3">
            <div class="col-6 form-group" [formGroup]="formGroup">
              <mat-form-field appearance="outline">
                <mat-label>Employee Start Date</mat-label>
                <input matInput [matDatepicker]="employeeStartDP" [formControl]="employeeStartDateForm">
                <mat-datepicker-toggle matSuffix [for]="employeeStartDP"></mat-datepicker-toggle>
                <mat-datepicker #employeeStartDP></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-6 form-group" [formGroup]="formGroup">
              <mat-form-field appearance="outline">
                <mat-label>Employee Start Date</mat-label>
                <input matInput [matDatepicker]="jobListingStartDP" [formControl]="jobListingStartDateForm">
                <mat-datepicker-toggle matSuffix [for]="jobListingStartDP"></mat-datepicker-toggle>
                <mat-datepicker #jobListingStartDP></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-6 form-group" [formGroup]="formGroup">
              <mat-form-field appearance="outline">
                <mat-label>Employee Start Date</mat-label>
                <input matInput [matDatepicker]="jobListingEndtDP" [formControl]="jobListingEndDateForm">
                <mat-datepicker-toggle matSuffix [for]="jobListingEndtDP"></mat-datepicker-toggle>
                <mat-datepicker #jobListingEndtDP></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12 form-group" [formGroup]="formGroup">
      <mat-card>
        <mat-card-title>Address</mat-card-title>
        <div class="container=fluid p-0">
          <div class="row mb-3">
            <div class="col-12 form-group" [formGroup]="formGroup">
              <mat-form-field appearance="outline">
                <mat-label>Address</mat-label>
                <input matInput [formControl]="addressForm">
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="container=fluid p-0">
          <div class="row mb-3">
            <div class="col-12 form-group" [formGroup]="formGroup">
              <mat-form-field appearance="outline">
                <mat-label>City</mat-label>
                <input matInput [formControl]="cityForm">
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="container=fluid p-0">
          <div class="row">
            <div class="col-6 form-group" [formGroup]="formGroup">
              <mat-form-field appearance="outline">
                <mat-label>Province</mat-label>
                <mat-select [formControl]="selectedProvinceForm" required>
                  <mat-option *ngFor="let province of provinces" [value]="province">
                    {{province | translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-6 form-group" [formGroup]="formGroup">
              <mat-form-field appearance="outline">
                <mat-label>Postal Code</mat-label>
                <input matInput [formControl]="postalCodeForm">
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12 form-group" [formGroup]="formGroup">
      <mat-card>
        <mat-card-title>Must haves:</mat-card-title>
        <mat-card-subtitle>You can have up to 5 questions</mat-card-subtitle>
        <div class="container=fluid p-0">
          <div class="row mb-3">
            <div class="col-4 form-group">
              <button mat-stroked-button class="create-job-button w-100" [disabled]="questionsForm.controls.length > 4" (click)="createQuestion()">
                Add
              </button>
            </div>
          </div>
        </div>
        <div class="container=fluid p-0" [formGroup]="formGroup">
          <ng-container formArrayName="Questions">
            <div class="row" *ngFor="let question of questionsForm.controls; index as i">
              <ng-container>
                <div class="col-12 d-flex justify-content-center align-items-center">
                  <mat-form-field appearance="outline" [formGroupName]="i">
                    <mat-label>Question</mat-label>
                    <input matInput formControlName="Question">
                  </mat-form-field>
                  <div class="remove-question-button">
                    <button mat-icon-button (click)="removeQuestion(i)">
                      <mat-icon>remove_circle_outline</mat-icon>
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </mat-card>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-card>
        <div class="container=fluid p-0">
          <div class="row">
            <div class="col-6">
              <button mat-stroked-button type="submit" class="create-job-button w-100"
                      (click)="submit()">
                Create Job
              </button>
            </div>
            <div class="col-6">
              <button mat-stroked-button class="clear-job-button w-100" (click)="clear()">Clear</button>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
