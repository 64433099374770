import AvaeleUser from '../../domain/AvaeleUser';
import * as SessionStoreActions from './session.actions';
import Company from '../../domain/Company';


export {
  SessionStoreModule
} from './session-store.module';

export {
  SessionStoreActions
};

export interface SessionState {
  avaeleUser: AvaeleUser;
  hrReps: Array<any>;
  company: Company;
}

export const selectState = (state: any) => {
  return state.session;
};

export const selectHrReps = (state: any) => {
  return state.session.hrReps;
};
export const selectUser = (state: any) => {
  return state.session.avaeleUser;
};
export const selectCompany = (state: any) => {
  return state.session.company;
};
