import {
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  QueryList, ViewEncapsulation
} from '@angular/core';
import {SelectableCardComponent} from '../selectable-card/selectable-card.component';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-selectable-card-group',
  templateUrl: './selectable-card-group.component.html',
  styleUrls: ['./selectable-card-group.component.scss']
})
export class SelectableCardGroupComponent implements OnInit, AfterViewInit {

  @Input() selected: any;
  @Output() selectedChange = new EventEmitter<any>();

  @ContentChildren(forwardRef(() => SelectableCardComponent), {
    descendants: true,
    emitDistinctChangesOnly: true
  }) selectableCardsQueryList: QueryList<SelectableCardComponent>;
  private selectableCards: Array<SelectableCardComponent> = [];
  private subscriptions: Array<Subscription> = [];

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.registerSelection();
    this.selectableCardsQueryList.changes.subscribe(_ => {
      this.subscriptions.forEach(sub => sub.unsubscribe());
      this.subscriptions = [];
      this.selectableCards = [];
      this.registerSelection();
    });
  }

  registerSelection(): void {
    this.selectableCardsQueryList.forEach((card, index) => {
      const sub = card.selectedChange.subscribe(_ => this.emit(index));
      this.subscriptions.push(sub);
      this.selectableCards.push(card);
    });
  }

  emit(index: number): void {
    this.selected = this.selectableCardsQueryList.get(index).value;
    this.selectedChange.emit(this.selected);
    this.selectableCards.forEach((card, i) => {
      card.selected = index === i;
    });
  }

}
