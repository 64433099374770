import {AvaeleUserStatus} from './AvaeleUserStatus';

export default class AvaeleUser {
  id: string;
  email: string;
  name: string;
  companyID: string;
  status: AvaeleUserStatus;

  constructor(id: string, email: string, name: string, companyID: string, status: AvaeleUserStatus) {
    this.id = id;
    this.email = email;
    this.name = name;
    this.companyID = companyID;
    this.status = status;
  }
}
