import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CandidateEmployeesComponent} from './candidate-employees/candidate-employees.component';
import {AnalyticsComponent} from './analytics/analytics.component';
import {RequestedCompensationChartComponent} from './analytics/requested-compensation-chart/requested-compensation-chart.component';
import {EmployeePageComponent} from './employee-page/employee-page.component';
import {CreateJobListingComponent} from './create-job-listing/create-job-listing.component';
import {AdminConsoleComponent} from './admin-console/admin-console.component';
import {SignInComponent} from './auth/sign-in/sign-in.component';
import {ChangePasswordComponent} from './auth/change-password/change-password.component';
import {SignUpCodeComponent} from './auth/sign-up-code/sign-up-code.component';
import {HomePageComponent} from './home-page/home-page.component';
import {AuthGuard} from './guard/auth.guard';
import {HrRepConsoleComponent} from './admin-console/hr-rep-console/hr-rep-console.component';
import {EmployeesConsoleComponent} from './admin-console/employees-console/employees-console.component';
import {AccountConsoleComponent} from './admin-console/account-console/account-console.component';
import {BillingConsoleComponent} from './admin-console/billing-console/billing-console.component';
import {OffersComponent} from './offers/offers.component';
import {ForgotPasswordCompleteComponent} from './auth/forgot-password-complete/forgot-password-complete.component';
import {ForgotPasswordStartComponent} from './auth/forgot-password-start/forgot-password-start.component';
import {PopulationRankedChartComponent} from './analytics/population-ranked-chart/population-ranked-chart.component';
import {AcceptedCompensationChartComponent} from './analytics/accepted-compensation-chart/accepted-compensation-chart.component';

const routes: Routes = [
  {path: 'signin', component: SignInComponent},
  {path: 'verify/:email', component: SignUpCodeComponent},
  {path: 'changePassword', component: ChangePasswordComponent},
  {path: 'forgotPasswordStart', component: ForgotPasswordStartComponent},
  {path: 'forgotPasswordComplete', component: ForgotPasswordCompleteComponent},
  {path: '', component: HomePageComponent, canActivate: [AuthGuard], children: [
      {path: 'candidateEmployees', component: CandidateEmployeesComponent},
      {path: 'employee', component: EmployeePageComponent},
      {path: 'offers', component: OffersComponent},
      {
        path: 'analytics', component: AnalyticsComponent, children: [
          {path: '', redirectTo: '/analytics/requestedCompensation', pathMatch: 'full'},
          {path: 'requestedCompensation', component: RequestedCompensationChartComponent},
          {path: 'acceptedCompensation', component: AcceptedCompensationChartComponent},
          {path: 'populationRankings', component: PopulationRankedChartComponent},
        ]
      },
      {
        path: 'createJobListing', component: CreateJobListingComponent
      },
      {
        path: 'console', component: AdminConsoleComponent, children: [
          {path: 'HR', component: HrRepConsoleComponent},
          {path: 'Employees', component: EmployeesConsoleComponent},
          {path: 'Account', component: AccountConsoleComponent},
          {path: 'Billing', component: BillingConsoleComponent}
        ]
      }
    ]},
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {
}
