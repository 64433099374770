import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Auth} from 'aws-amplify';

@Component({
  selector: 'app-sign-up-code',
  templateUrl: './sign-up-code.component.html',
  styleUrls: ['./sign-up-code.component.scss']
})
export class SignUpCodeComponent implements OnInit {

  private email: string;

  constructor(private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.email = atob(params.email);
    });
  }

  onCodeCompleted($event: string) {
    if (this.email) {
      Auth.confirmSignUp(this.email, $event)
        .then((result) => {
          this.router.navigate([`signin/ + ${btoa(this.email)}`]);
        })
        .catch((e) => console.error(e));
    }
  }
}
