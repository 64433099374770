import {Component, OnInit, ViewChild} from '@angular/core';
import {Subject} from "rxjs";
import JobListing from "../domain/JobListing";
import {JobListingService} from "../services/job-listing.service";
import {AppUser} from "../domain/AppUser";
import {MatTableDataSource} from "@angular/material/table";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {MatSort} from "@angular/material/sort";
import JobOffer from "../domain/JobOffer";

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class OffersComponent implements OnInit {
  private destroyed$ = new Subject<boolean>();
  private _selectedJobListing: JobListing;
  private _appUsers: Array<AppUser>;
  private jobOfferByAppUser: Map<string, JobOffer>;

  @ViewChild(MatSort, {static: false}) set sort(sort: MatSort) {
    if (sort && this.dataSource) { // initially setter gets called with undefined
      this.dataSource.sort = sort;
    }
  }

  dataSource: MatTableDataSource<AppUser>;
  columns = [{prop: 'name'}, {prop: 'compensation'}, {prop: 'certifications'}, {prop: 'experience'}];
  columnsToDisplay = [
    {prop: 'name', name: 'Name'},
    {prop: 'compensation', name: 'Compensation'},
    {prop: 'experience', name: 'Experience'},
  ];
  expandedElement: AppUser | null;
  columnsToDisplayWithExpand = [...this.columnsToDisplay.map(column => column.prop), 'expand'];

  constructor(public jobListingService: JobListingService) {
  }

  ngOnInit(): void {
  }

  get selectedJobListing(): JobListing {
    return this._selectedJobListing;
  }

  set selectedJobListing(value: JobListing) {
    console.log('Getting job offers for job listing');
    this.jobListingService.getJobOffersFromJobListing(value)
      .subscribe( res => {
        this.jobOfferByAppUser = new Map<string, JobOffer>();
        res.forEach( v => this.jobOfferByAppUser.set(v.second.email, v.first));
        const users = res.map(v => v.second);
        this.appUsers = users;
        this.dataSource = new MatTableDataSource<AppUser>(users);
      });
    this._selectedJobListing = value;
  }

  get appUsers(): Array<AppUser> {
    return this._appUsers;
  }

  set appUsers(value: Array<AppUser>) {
    this._appUsers = value;
  }
}
