import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {JobListingState} from './index';
import {
  ActionTypes,
  addJobListingStart,
  addJobListingSuccess,
  getJobListingsStart,
  getJobListingsSuccess, sendGetJobOfferFromJobListingStart, sendGetJobOfferFromJobListingSuccess,
  sendJobOfferStart, sendJobOfferSuccess
} from './job-listings.actions';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {JobListingService} from '../../services/job-listing.service';
import {of} from 'rxjs';

@Injectable()
export default class JobListingEffects {
  constructor(private actions$: Actions,
              public jobListingStore: Store<JobListingState>,
              public jobListingService: JobListingService) {
  }

  getAllJobListing$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType<typeof getJobListingsStart>(ActionTypes.GET_JOB_LISTINGS_START),
        switchMap(action => this.jobListingService.getJobListings('ORG%23123')
          .pipe(
            map(jobListingResult => getJobListingsSuccess({jobListings: jobListingResult})),
            catchError(err => {
              console.error(ActionTypes.GET_JOB_LISTINGS_START, err);
              return of(err);
            })
          )
        )
      )
  );

  addJobListingStart$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType<typeof addJobListingStart>(ActionTypes.ADD_JOB_LISTING_START),
        //TODO: action['jobListing'] is a bit weird
        switchMap(action => this.jobListingService.createJobListing(action['jobListing'])
          .pipe(
            map(createJobListingResponse => addJobListingSuccess({response: createJobListingResponse})),
            catchError(err => {
              console.error(ActionTypes.ADD_JOB_LISTING_START, err);
              return of(err);
            })
          )
        )
      ));

  //TODO: we should just trigger the getJobListings action instead
  addJobListingSuccess$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType<typeof addJobListingSuccess>(ActionTypes.ADD_JOB_LISTING_SUCCESS),
        switchMap(action => this.jobListingService.getJobListings('ORG%23123')
          .pipe(
            map(jobListingResult => getJobListingsSuccess({jobListings: jobListingResult})),
            catchError(err => {
              console.error(ActionTypes.GET_JOB_LISTINGS_START, err);
              return of(err);
            })
          )
        )
      ));

  sendJobOfferStart$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType<typeof sendJobOfferStart>(ActionTypes.SEND_OFFER_START),
        switchMap(action => this.jobListingService.sendJobOffer(action['jobListing'], action['user'])
          .pipe(
            map(jobOffer => sendJobOfferSuccess({offer: jobOffer})
            )
          )
        )
      )
  );
}
