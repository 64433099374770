import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {selectCompany, SessionState} from './index';
import {ActionTypes, getHrRepsStart, getHrRepsSuccess, getSignedInUserStart, getSignedInUserSuccess} from './session.actions';
import {map, switchMap, withLatestFrom} from 'rxjs/operators';
import {AdminService} from '../../services/admin.service';
import {cloneDeep} from 'lodash';

@Injectable()
export default class SessionEffects {
  constructor(private actions$: Actions,
              public sessionStore: Store<SessionState>,
              public adminService: AdminService) {
  }

  getHrReps$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType<typeof getHrRepsStart>(ActionTypes.GET_HR_REPS_START),
        withLatestFrom(this.sessionStore.select(selectCompany)),
        switchMap(([, company]) =>
          this.adminService.getHrReps(company.id)
            .pipe(
              map(getHrRepsResponse => getHrRepsSuccess({hrReps: getHrRepsResponse}))
            )
        )
      )
  );

  getSignedInUserStart$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType<typeof getSignedInUserStart>(ActionTypes.GET_SIGNED_IN_USER_START),
        switchMap((actionData) => {
          console.log('ACTION DATA', actionData)
          return this.adminService.getUserFromDynamo(actionData['pk'], actionData['sk'], actionData['userType'])
            .pipe(
              map(getUserResponse => getSignedInUserSuccess({user: getUserResponse}))
            );
          }
        )
      )
  );


}
