/// <reference  types="@types/googlemaps"  />
import {Component} from '@angular/core';
import {lyl, StyleRenderer, ThemeVariables, WithStyles} from '@alyle/ui';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

const STYLES = (theme: ThemeVariables) => ({
  $global: lyl`{
    body {
      background-color: ${theme.background.default}
      color: ${theme.text.default}
      font-family: ${theme.typography.fontFamily}
      margin: 0
      direction: ${theme.direction}
    }
  }`,
  root: lyl`{
    display: block
  }`
});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    StyleRenderer
  ]
})
export class AppComponent implements WithStyles {
  readonly classes = this.sRenderer.renderSheet(STYLES, true);

  links = [
    {title: 'Candidates', fragment: '/candidateEmployees'},
    {title: 'Analytics', fragment: '/analytics', premium: true},
    {title: 'Job Listings', fragment: '/createJobListing'},
    {title: 'Console', fragment: '/console'}
  ];

  constructor(
    readonly sRenderer: StyleRenderer,
    private router: Router,
    translate: TranslateService
  ) {
    translate.setDefaultLang('en');
    translate.use('en');
  }

  title = 'Avaele';
  openedMenu: boolean = true;

  toggleSideBar() {
    this.openedMenu = !this.openedMenu;
  }
}
