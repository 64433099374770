export default class Address {
  // TODO: Remove
  streetNumber: string;
  streetName: string;
  unit: string;
  city: string;
  province: string;
  country: string;
  postalCode: string;

  constructor(streetNumber?: string, streetName?: string, unit?: string, city?: string, province?: string, country?: string, postalCode?: string) {
    this.streetNumber = streetNumber || '';
    this.streetName = streetName || '';
    this.unit = unit || '';
    this.city = city || '';
    this.province = province || '';
    this.country = country || '';
    this.postalCode = postalCode || '';
  }

}
