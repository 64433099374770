import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import {JobListingStoreModule} from './job-listing-store';
import {EffectsModule} from '@ngrx/effects';
import {SessionStoreModule} from './session-store';

@NgModule({
  imports: [
    CommonModule,
    JobListingStoreModule,
    SessionStoreModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([])
  ],
  declarations: []
})
export class RootStoreModule {
}
