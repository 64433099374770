import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import CompensationAnalyticRequest from '../domain/analytics/CompensationAnalyticRequest';
import RankedChartAnalyticRequest from '../domain/analytics/RankedChartAnalyticRequest';

import {LocationType} from '../domain/LocationType';

@Injectable({
  providedIn: 'root'
})
export class AnalyticService {

  // TODO: turn restEndPoint into environment variable
  private restEndPoint = environment.dev_backend + 'analytics/';

  constructor(private http: HttpClient) {
  }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,POST,PATCH,DELETE,PUT,OPTIONS',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, content-type',
    })
  };

  getCompensation(request: CompensationAnalyticRequest): Observable<any>{
    return this.http.post<Array<any>>(this.restEndPoint + 'compensation', request, this.httpOptions);
  }

  getAcceptedCompensation(request: CompensationAnalyticRequest): Observable<any>{
    return this.http.post<Array<any>>(this.restEndPoint + 'compensation_accepted', request, this.httpOptions);
  }

  getUserRankings(request: RankedChartAnalyticRequest): Observable<any>{
    if (request.locationType == LocationType.HOME) {
      return this.http.post<Array<any>>(this.restEndPoint + 'user_rankings_home', request, this.httpOptions);
    } else {
      return this.http.post<Array<any>>(this.restEndPoint + 'user_rankings_work', request, this.httpOptions);
    }
  }
}
