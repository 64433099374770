import {Injectable} from '@angular/core';
import JobListing from '../domain/JobListing';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import AvaeleUser from '../domain/AvaeleUser';
import JobOffer from '../domain/JobOffer';
import {JobOfferAppUserPair} from '../domain/JobOfferAppUserPair';

@Injectable({
  providedIn: 'root'
})
export class JobListingService {

  constructor(private http: HttpClient) {
  }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,POST,PATCH,DELETE,PUT,OPTIONS',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, content-type',
    })
  };

  // private restEndPoint = environment.backend + 'jobListing/';
  private jobListingRestEndPoint = environment.dev_backend + 'jobListing/';
  private jobOfferRestEndPoint = environment.dev_backend + 'jobOffer/';


  getJobs(): Array<JobListing> {
    return [
      new JobListing('Welder', 'oil'),
      new JobListing('Pipe Fitter', 'oil'),
      new JobListing('American', 'oil'),
    ];
  }

  getJobListings(companyId: string): Observable<Array<JobListing>> {
    return this.http.get<Array<JobListing>>(this.jobListingRestEndPoint + 'getAllJobListings/' + companyId, this.httpOptions);
  }

  createJobListing(jobListing: JobListing) {
    return this.http.post<any>(this.jobListingRestEndPoint + 'create', jobListing, this.httpOptions).pipe(
      tap(
        (val) => {
          console.log('POST call successful value returned in body', val);
        },
        response => {
          console.log('POST call in error', response);
        },
        () => {
          console.log('The POST observable is now completed.');
        })
      );
  }

  getJobOffersFromJobListing(jobListing: JobListing): Observable<Array<JobOfferAppUserPair>> {
    return this.http.get<Array<JobOfferAppUserPair>>(this.jobOfferRestEndPoint + 'getAllOffersForJobListing/' + jobListing.companyId + '/' + jobListing.jobListingId, this.httpOptions).pipe(
      tap(
        (val) => {
          console.log('POST call successful value returned in body', val);
        },
        response => {
          console.log('POST call in error', response);
        },
        () => {
          console.log('The POST observable is now completed.');
        })
    );
  }

  sendJobOffer(jobListing: JobListing, user: AvaeleUser): Observable<JobOffer>{
    const jobOffer: JobOffer = {
      jobListingId: jobListing.jobListingId,
      companyId: jobListing.companyId,
      appUserId: user.email,
      expirationDate: (new Date()).toISOString()
    };
    return this.http.put<JobOffer>(this.jobOfferRestEndPoint   + 'createJobOffer', jobOffer, this.httpOptions);
  }
}
