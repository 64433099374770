import {Experience} from '../Experience';
import {Occupation} from '../Occupation';
import {Shift} from '../Shift';
import AnalyticRequest from './AnalyticRequest';
import AnalyticsLocationFilter from './AnalyticsLocationFilter';
import {LocationType} from '../LocationType';

export default class RankedChartAnalyticRequest extends AnalyticRequest {
  limit : number;
  useAcceptanceValues : boolean = false;
  ascendingOrder : boolean = true;

  constructor(startDate: string, endDate: string, experienceList: Array<Experience>, occupationList: Array<Occupation>, shiftList: Array<Shift>, locationFilter: AnalyticsLocationFilter, locationType: LocationType, limit: number, useAcceptanceValues: boolean, ascendingOrder: boolean) {
    super(startDate, endDate, experienceList, occupationList, shiftList, locationFilter, locationType);
    this.limit = limit;
    this.useAcceptanceValues = useAcceptanceValues;
    this.ascendingOrder = ascendingOrder;
  }
}
