import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Auth} from 'aws-amplify';
import {switchMap} from 'rxjs/operators';
import {fromPromise} from 'rxjs/internal-compatibility';
import {environment} from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
    if (!req.url.includes(environment.constantsS3Bucket)) {
      return fromPromise(Auth.currentAuthenticatedUser()).pipe(
        switchMap(authenticatedUser => {
          const headers = req.headers
            .set('Authorization', 'Bearer ' + authenticatedUser.signInUserSession.accessToken.jwtToken);
          const requestClone = req.clone({
            headers
          });
          return next.handle(requestClone);
        })
      );
    }
    return next.handle(req);
  }
}
