import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexGrid,
  ApexLegend,
  ApexMarkers,
  ApexStroke,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexYAxis,
  ChartComponent
} from 'ng-apexcharts';
import TimeSeriesChart from '../../domain/TimeSeriesChart';
import {AnalyticService} from '../../services/analytics.service';
import CompensationAnalyticRequest from '../../domain/analytics/CompensationAnalyticRequest';
import AnalyticsLocationFilter from '../../domain/analytics/AnalyticsLocationFilter';
import {Province} from '../../domain/analytics/Province';
import {FormControl, FormGroup} from '@angular/forms';
import {Shift} from '../../domain/Shift';
import {Experience} from '../../domain/Experience';
import {JobType} from '../../domain/JobType';
import {Occupation} from '../../domain/Occupation';
import {formatDate} from '@angular/common';
import {LocationType} from '../../domain/LocationType';
import {PlaceSearchResult} from '../analytics.component';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  colors: string[];
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-accepted-compensation-chart',
  templateUrl: './accepted-compensation-chart.component.html',
  styleUrls: ['./accepted-compensation-chart.component.scss']
})
export class AcceptedCompensationChartComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(ChartComponent) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  provinceCityAggregate = [];
  selectedExperienceForm = new FormControl(new Array<Experience>(), []);
  startDate = new FormControl('', []);
  endDate = new FormControl('', []);
  minCompensationForm = new FormControl('', []);
  maxCompensationForm = new FormControl('', []);
  selectedCityForm = new FormControl(new Array<string>(), []);
  selectedProvincesForm = new FormControl('', []);
  selectedShiftForm = new FormControl(new Array<Shift>(), []);
  selectedOccupationForm = new FormControl(new Array<Occupation>(), []);
  selectedRadiusForm = new FormControl(new Array<Number>(), []);
  selectedLocationType = new FormControl(new Array<LocationType>(), []);

  location = new FormControl('', []);

  occupations = [];
  shifts = [];
  experiences = [];
  radiuses = [];
  locationTypes = [];
  jobTypes = [];
  provinces = [];

  formGroup: FormGroup;
  isCollapsed = false;
  private _queryDone = false;

  @ViewChild('inputField')
  inputField!: ElementRef;

  @Input() placeholder = 'Enter address...';

  @Output() placeChanged = new EventEmitter<PlaceSearchResult>();

  autocomplete: google.maps.places.Autocomplete | undefined;

  listener: any;

  constructor(private analyticService: AnalyticService, @Inject(LOCALE_ID) private locale: string, private ngZone: NgZone) {
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      selectedExperienceForm: this.selectedExperienceForm,
      startDate: this.startDate,
      endDate: this.endDate,
      minCompensationForm: this.minCompensationForm,
      maxCompensationForm: this.maxCompensationForm,
      selectedCityForm: this.selectedCityForm,
      selectedProvincecForm: this.selectedProvincesForm,
      selectedShiftForm: this.selectedShiftForm,
      selectedOccupationForm: this.selectedOccupationForm,
      location : this.location,
      selectedRadiusForm : this.selectedRadiusForm,
      selectedLocationType : this.selectedLocationType
    });

    this.shifts = Object.keys(Shift).filter(x => !(parseInt(x) >= 0));
    this.experiences = Object.keys(Experience).filter(x => !(parseInt(x) >= 0));
    this.jobTypes = Object.keys(JobType).filter(x => !(parseInt(x) >= 0));
    this.occupations = Object.keys(Occupation).filter(x => !(parseInt(x) >= 0));
    this.provinces = Object.keys(Province).filter(x => !(parseInt(x) >= 0));
    this.radiuses = [10, 20, 50, 100, 200, 500]
    this.locationTypes = Object.keys(LocationType).filter(x => !(parseInt(x) >= 0));

    this.chartOptions = {
      series: [],
      chart: {
        type: "area",
        stacked: false,
        height: 425,
      },
      colors: ["#008FFB", "#00E396", "#CED4DC"],
      xaxis: {
        type: 'datetime',
        labels: {
          style: {
            colors: '#ffffff'
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        labels: {
          colors: '#ffffff'
        },
        position: "top",
        horizontalAlign: "left"
      },
      yaxis: {
        tooltip: {
          enabled: true
        },
        labels: {
          style: {
            colors: '#ffffff'
          }
        }
      },
    };
  }

  ngAfterViewInit(): void {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.inputField.nativeElement,
      {
        types: ['locality', 'administrative_area_level_3'],
        componentRestrictions: {'country': ['CA']},
        fields : ['geometry']
      }
    );

    this.autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place = this.autocomplete?.getPlace();
        const result: PlaceSearchResult = new PlaceSearchResult( this.inputField.nativeElement.value, place?.geometry?.location)

        this.placeChanged.emit(result);
        this.location.setValue(result)

        if (this.selectedRadiusForm.value.length == 0) {
          this.selectedRadiusForm.setValue(100);
        }

        if (this.selectedLocationType.value.length == 0) {
          this.selectedLocationType.setValue(LocationType.WORK)
        }
      });
    });
  }


  query() {
    let analyticsQueryRequest = new CompensationAnalyticRequest(
      this.startDate.value != '' ? formatDate(this.startDate.value, 'yyyy-MM-dd', this.locale) : '',
      this.endDate.value != '' ? formatDate(this.endDate.value, 'yyyy-MM-dd', this.locale) : '',
      this.selectedExperienceForm.value,
      this.selectedOccupationForm.value,
      this.selectedShiftForm.value,

      this.location.value ? new AnalyticsLocationFilter(this.location.value.latlng.lat(),
        this.location.value.latlng.lng(),
        this.selectedRadiusForm.value) : null,
      this.selectedLocationType.value.length > 0 ? this.selectedLocationType.value  : null,
      this.minCompensationForm.value,
      this.maxCompensationForm.value
    );

    this.analyticService.getAcceptedCompensation(analyticsQueryRequest).subscribe(compGraph => {
      const timeSeriesChart = compGraph as TimeSeriesChart;
      let series = [];
      for (let i = 0; i < timeSeriesChart.yData.length; i = i + 1) {
        let data = [];
        for (let j = 0; j < timeSeriesChart.xData.length; j = j + 1) {
          data.push({
            x: new Date(timeSeriesChart.xData[j]),
            y: (Math.round((timeSeriesChart.yData[i][j] + Number.EPSILON) * 100) / 100)
          });
        }
        series.push({
          data: data,
          name: timeSeriesChart.names[i]
        });
      }
      let sortedSeries = []
      // sortedSeries[0] = series.find(s => s.name == 'Maximum')
      sortedSeries[0] = series.find(s => s.name == 'Average')
      // sortedSeries[2] = series.find(s => s.name == 'Minimum')

      this.isCollapsed = true;
      this.chart.updateSeries(sortedSeries);
    });
  }

  ngOnDestroy(): void {
    if (this.autocomplete) {
      google.maps.event.clearInstanceListeners(this.autocomplete);
    }
  }
}
