<div class="container-fluid w-25 mt-5">
  <div class="row card">
    <div class="col-12 card-body">
      <div class="container-fluid w-100">
        <div class="row mb-5">
          <div class="col-12 d-flex">
            <h1 class="page-title">LOGIN</h1>
          </div>
        </div>
        <div class="col-12">
          <div class="row mb-5">
            <div class="col-12" [formGroup]="formGroup" (keyup)="onSignIn($event)">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Email Address</mat-label>
                <input matInput [formControl]="emailForm" name="email" type="email" [(ngModel)]="emailAddress" email
                       placeholder="Enter Username" required>
              </mat-form-field>
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-12" [formGroup]="formGroup" (keyup)="onSignIn($event)">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput [formControl]="passwordForm"
                       [(ngModel)]="password"
                       name="password"
                       [type]="hidePassword ? 'password' : 'text'"
                       minlength="8"
                       placeholder="Enter Password">
                <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
              </mat-form-field>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <button mat-stroked-button class="login-button w-100" type="submit" (click)="onSignIn()">Login
              </button>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <button mat-stroked-button class="forgot-password-button w-100" type="submit" (click)="forgotPassword()">Forgot Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
