import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import JobListing from '../../domain/JobListing';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {Shift} from '../../domain/Shift';
import {Experience} from '../../domain/Experience';
import {JobListingService} from '../../services/job-listing.service';
import {JobType} from '../../domain/JobType';
import {Occupation} from '../../domain/Occupation';
import {select, Store} from '@ngrx/store';
import {JobListingState} from '../../store';
import {SessionState} from 'http2';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {selectCompany} from '../../store/session-store';
import {Observable, Subject} from 'rxjs';
import {MatChipInputEvent, MatChipList} from '@angular/material/chips';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {S3Service} from '../../services/s3.service';
import {ulid} from 'ulid';
import {JobListingStatus} from '../../domain/JobListingStatus';
import Address from '../../domain/Address';
import {addJobListingStart} from '../../store/job-listing-store/job-listings.actions';

@Component({
  selector: 'app-create-job-listing-card',
  templateUrl: './create-job-listing-card.component.html',
  styleUrls: ['./create-job-listing-card.component.scss'],
})
export class CreateJobListingCardComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<boolean>();
  selectedOccupationForm = new FormControl('', [Validators.required]);
  selectedExperienceForm = new FormControl('', [Validators.required]);
  selectedJobTypeForm = new FormControl('', [Validators.required]);
  selectedShiftForm = new FormControl('', [Validators.required]);
  employeeStartDateForm = new FormControl('', [Validators.required]);
  jobListingStartDateForm = new FormControl('', [Validators.required]);
  jobListingEndDateForm = new FormControl('', [Validators.required]);
  addressForm = new FormControl('', [Validators.required]);
  cityForm = new FormControl('', [Validators.required]);
  selectedProvinceForm = new FormControl('', [Validators.required]);
  postalCodeForm = new FormControl('', [Validators.required, Validators.pattern('^(\\d{5}(-\\d{4})?|[aA-zZ]\\d[aA-zZ] *\\d[aA-zZ]\\d)$')]);
  openingsForm = new FormControl(1, [Validators.required]);
  descriptionForm = new FormControl('', [Validators.required]);
  certInputForm = new FormControl('', []);
  certsForm = new FormControl([], this.validatetQualifications);
  questionsForm = new FormArray([]);

  // TODO see if we can offload this to some sort of address library
  provinces = [
    'AB',
    'BC',
    'MB',
    'NB',
    'NL',
    'NT',
    'NS',
    'NU',
    'ON',
    'PE',
    'QC',
    'SK',
    'YT',
  ];
  formGroup: FormGroup;
  jobListings: Array<JobListing>;

  private _bannerImg: any;

  occupations = [];
  shifts = [];
  experiences = [];
  jobTypes = [];
  certs: Array<string> = [];
  public addOnBlur = true;
  private _companyId: string;
  private _defaultBannerImg: string;
  private _companyName: string;

  @ViewChild('qualCertsInput') qualCertsInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('certsList') certsList: MatChipList;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  visible = true;
  selectable = true;
  removable = true;
  filteredCerts$: Observable<string[]>;
  jobListing: JobListing = new JobListing();

  constructor(private jobListingService: JobListingService,
              private s3Service: S3Service,
              public jobListingStore: Store<JobListingState>,
              public sessionStore: Store<SessionState>) {
    this.jobListings = jobListingService.getJobs();
    this.sessionStore
      .pipe(select(selectCompany))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((storeElement) => {
        if (storeElement) {
          this.companyId = storeElement.id;
          this.companyName = storeElement.name;
          this.defaultBannerImg = storeElement.imageUrl;
        }
      });

    s3Service.getS3ConstantValue('Qualifications.json').toPromise().then((response) => {
      this.certs = response.value;

      this.formGroup
        .get('Certs')
        .statusChanges.subscribe(
        status => (this.certsList.errorState = status === 'INVALID')
      );

      this.filteredCerts$ = this.formGroup.get('CertInput').valueChanges.pipe(
        startWith(''),
        map(value => this.certFilter(value))
      );
    });
  }

  @Input()
  set selectedJobListing(value: JobListing) {

    if (value) {
      this.jobListing = value;
      this.selectedOccupationForm.setValue(value.occupation);
      this.selectedShiftForm.setValue(value.shift);
      this.selectedExperienceForm.setValue(value.minExperience);
      this.descriptionForm.setValue(value.description);
      this.selectedJobTypeForm.setValue(value.jobType);
      this.bannerImg = value.imageUrl;
      this.addressForm.setValue( value.address.streetNumber + value.address.streetName);
      this.postalCodeForm.setValue(value.address.postalCode);
      this.selectedProvinceForm.setValue(value.address.province);
      this.cityForm.setValue(value.address.city);
      this.openingsForm.setValue(value.numOpenings);
      this.certsForm.setValue(value.qualCertsList);
      // this.qualCerts = value.qualCertsList;
    }
  }

  ngOnInit(): void {
    this.shifts = Object.keys(Shift).filter(x => !(parseInt(x) >= 0));
    this.experiences = Object.keys(Experience).filter(x => !(parseInt(x) >= 0));
    this.jobTypes = Object.keys(JobType).filter(x => !(parseInt(x) >= 0));
    this.occupations = Object.keys(Occupation).filter(x => !(parseInt(x) >= 0));

    this.formGroup = new FormGroup({
      Address: this.addressForm,
      Occupation: this.selectedOccupationForm,
      Shift: this.selectedShiftForm,
      Experience: this.selectedExperienceForm,
      JobType: this.selectedJobTypeForm,
      Province: this.selectedProvinceForm,
      City: this.cityForm,
      EmployeeStartDate: this.employeeStartDateForm,
      JobListingStartDate: this.jobListingStartDateForm,
      JobListingEndDate: this.jobListingEndDateForm,
      PostalCode: this.postalCodeForm,
      Openings: this.openingsForm,
      Description: this.descriptionForm,
      CertInput: this.certInputForm,
      Certs: this.certsForm,
      Questions: this.questionsForm
    });
  }


  get defaultBannerImg(): string {
    return this._defaultBannerImg;
  }

  set defaultBannerImg(value: string) {
    this._defaultBannerImg = value;
  }

  set companyId(value: string) {
    this._companyId = value;
  }

  get bannerImg(): any {
    if (this._bannerImg) {
      return this._bannerImg;
    } else {
      return this.defaultBannerImg;
    }
  }

  set bannerImg(value: any) {
    this._bannerImg = value;
  }

  get companyName(): string {
    return this._companyName;
  }

  set companyName(value: string) {
    this._companyName = value;
  }


  submit(): void {
    const jobListing = new JobListing(
      this.selectedOccupationForm.toString(),
      'Pipe Fitter');
    jobListing.jobListingId = ulid();
    // TODO: parameterize this
    jobListing.companyId = '123';

    jobListing.occupation = this.selectedOccupationForm.value;
    jobListing.shift = this.selectedShiftForm.value;
    jobListing.minExperience = this.selectedExperienceForm.value;
    jobListing.description = this.descriptionForm.value;
    jobListing.jobListingState = JobListingStatus.ACTIVE;
    jobListing.imageUrl = this.bannerImg.toString();

    const employeeStartDateObj =  new Date(this.employeeStartDateForm.value);

    const jobListingStartDateObj =  new Date(this.jobListingStartDateForm.value);

    const jobListingEndDateObj =  new Date(this.jobListingStartDateForm.value);

    jobListing.employeeStartDate = employeeStartDateObj.toISOString();
    jobListing.jobListingStartDate = jobListingStartDateObj.toISOString();
    jobListing.jobListingEndDate = jobListingEndDateObj.toISOString();
    const address = new Address();
    const addressVal = this.addressForm.value;
    address.streetNumber = addressVal.substr(0, addressVal.indexOf(' '));
    address.streetName = addressVal.substr(addressVal.indexOf(' '));
    address.city = this.cityForm.value;
    address.postalCode = this.postalCodeForm.value;
    address.province = this.selectedProvinceForm.value;
    jobListing.address = address;
    jobListing.numOpenings = this.openingsForm.value;
    jobListing.qualCertsList = this.certsForm.value;
    jobListing.companyName = this.companyName;
    jobListing.questions = [];
    this.questionsForm.value.forEach(
      q => {
        jobListing.questions.push({
          question: q.Question,
          answer: q.Answer,
          type: q.Type
        });
      }
    );

    console.log('jobListing', jobListing);

    this.jobListingStore.dispatch(addJobListingStart({jobListing}));
    console.log(this.formGroup.valid);
  }

  clear(): void {
    this.selectedOccupationForm.setValue('');
    this.selectedShiftForm.setValue('');
    this.selectedExperienceForm.setValue('');
    this.descriptionForm.setValue('');
    this.selectedJobTypeForm.setValue('');
    this.addressForm.setValue( '');
    this.postalCodeForm.setValue('');
    this.selectedProvinceForm.setValue('');
    this.cityForm.setValue('');
    this.openingsForm.setValue(1);
    this.certsForm.setValue([]);
    this.clearJobListingQualifications();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public addQualification(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (value.trim()) {
      const matches = this.certs.filter(
        cert => cert.toLowerCase() === value
      );
      const formValue = this.formGroup.get('Certs').value;
      const matchesNotYetSelected =
        formValue === null
          ? matches
          : matches.filter(x => !formValue.find(y => y === x));
      if (matchesNotYetSelected.length === 1) {
        this.jobListing.qualCertsList.push(matchesNotYetSelected[0]);
        this.formGroup.get('Certs').setValue(this.jobListing.qualCertsList);
        this.formGroup.get('CertInput').setValue('');
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeQualifications(cert: string) {
    const index = this.jobListing.qualCertsList.indexOf(cert);
    if (index >= 0) {
      this.jobListing.qualCertsList.splice(index, 1);
      this.formGroup.get('Certs').setValue(this.jobListing.qualCertsList);
      this.formGroup.get('CertInput').setValue('');
    }
  }

  clearJobListingQualifications(): void{
    this.jobListing.qualCertsList = [];
    this.formGroup.get('Certs').setValue([]);
    this.formGroup.get('CertInput').setValue('');
  }

  private validatetQualifications(certs: FormControl) {
    if (certs.value && certs.value.length === 0) {
      return {
        validateQualificationsArray: { valid: false }
      };
    }

    return null;
  }

  public selectQualifications(event: MatAutocompleteSelectedEvent): void {
    if (!event.option) {
      return;
    }

    const value = event.option.value;

    if (value && !this.jobListing.qualCertsList.includes(value)) {
      this.jobListing.qualCertsList.push(value);
      this.formGroup.get('Certs').setValue(this.jobListing.qualCertsList);
      this.formGroup.get('CertInput').setValue('');
    }
  }

  private certFilter(value: any): string[] {
    const filterValue = value === null || value instanceof Object ? '' : value.toLowerCase();
    const matches = this.certs.filter(cert =>
      cert.toLowerCase().includes(filterValue)
    );
    const formValue = this.formGroup.get('Certs').value;
    return formValue === null
      ? matches
      : matches.filter(x => !formValue.find(y => y === x));
  }

  createQuestion(): void{
    const questionGroup = new FormGroup({
        Question: new FormControl('', [Validators.required]),
        Answer: new FormControl('true', [Validators.required]),
        Type: new FormControl('boolean', [Validators.required]),
      }
    );
    this.questionsForm.push(questionGroup);
  }

  removeQuestion(i: number): void{
    this.questionsForm.removeAt(i);
  }
}
