import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SelectionType} from '@swimlane/ngx-datatable';
import {select, Store} from '@ngrx/store';
import {getJobListings, getSelectedJobListing, JobListingState} from '../../store';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Occupation} from '../../domain/Occupation';
import {getJobListingsStart, selectJobListing} from '../../store/job-listing-store/job-listings.actions';
import JobListing from '../../domain/JobListing';
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-job-listing-list-card',
  templateUrl: './job-listing-list-card.component.html',
  styleUrls: ['./job-listing-list-card.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class JobListingListCardComponent implements OnInit, OnDestroy {

  private destroyed$ = new Subject<boolean>();

  @ViewChild('container') container: ElementRef;
  @ViewChild('controlPanel') controlPanel: ElementRef;

  dataSource: Array<JobListing> = [];
  columnsToDisplay = [
    {prop: 'jobListingState', name: 'State'},
    {prop: 'occupation', name: 'Occupation'},
    {prop: 'shift', name: 'Shift'},
    {prop: 'minExperience', name: 'Experience'},
    {prop: 'numHoursPerWeek', name: 'Hours per week'},
    {prop: 'jobType', name: 'Type'},
    {prop: 'employeeStartDate', name: 'Employee Start Date'},
    {prop: 'jobListingStartDate', name: 'Listing Start Date'},
    {prop: 'jobListingEndDate', name: 'Listing End Date'},
  ];
  columnsToDisplayWithExpand = [...this.columnsToDisplay.map(column => column.prop), 'duplicate', 'delete', 'expand'];
  expandedElement: JobListing | null;

  occupations = [];

  rows = [];
  filteredRows = [];

  columns = [
    {prop: 'occupation', name: 'Occupation'},
    {prop: 'shift', name: 'Shift'},
    {prop: 'minExperience', name: 'Experience'},
    {prop: 'description', name: 'Description'}
  ];

  limit = 14;

  selectionType: SelectionType = SelectionType.single;
  selectedOccupation: Occupation;

  selected: Array<JobListing> = [];

  constructor(public jobListingStore: Store<JobListingState>) {

    this.occupations = Object.keys(Occupation).filter(x => !(parseInt(x) >= 0));

    this.jobListingStore.dispatch(getJobListingsStart({companyId: 'ORG#123', hrRepId: ''}));

    this.jobListingStore
      .pipe(select(getJobListings))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((jobListings) => {
        this.dataSource = [...jobListings];
        this.filteredRows = [...jobListings];
      });

    jobListingStore
      .pipe(select(getSelectedJobListing))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((storeElement) => {
        // Array::length <= 1
        this.selected = [storeElement];
      });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  sortRows(jobListingTitle: any): void {
    if (jobListingTitle) {
      this.filteredRows = this.rows.filter((result) => result.occupation === jobListingTitle);
    }
  }

  onActivate($event: any) {

  }

  onSelect($event: any) {
    this.jobListingStore.dispatch(selectJobListing({jobListing: $event.selected[0]}));
  }

  getHeight() {
    if (this.container && this.controlPanel) {
      return this.container.nativeElement.offsetHeight - this.controlPanel.nativeElement.offsetHeight;
    }
  }

  singleSelectCheck (row:any) {
    return this.selected.indexOf(row) === -1;
  }
}

export interface JobListingColumn {
  [key: string]: any;
}
