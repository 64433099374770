<h3 class="page-title">HR</h3>
<div class="card w-100">
  <div class="card-body py-4">
    <div class="container-fluid w-100">
      <div class="row mb-3">
        <div class="col-1">
          <button mat-flat-button class="w-100 add-hr-popup" (click)="toggleAddUser()" *ngIf="!addUserFlag">
            Add
          </button>
        </div>
      </div>
      <div *ngIf="addUserFlag" class="row mb-3">
        <div class="col-12">
          <div class="card add-user-card w-100">
            <div class="card-body py-4">
              <div class="container-fluid w-100">
                <div class="row mb-2">
                  <div class="col-4">
                    <h4 class="h4">Add HR Rep</h4>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-4">
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-label>Name</mat-label>
                      <input matInput [formControl]="nameForm" (keyup)="createNewHrRep($event)">
                    </mat-form-field>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-4" [formGroup]="formGroup">
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-label>Email Address</mat-label>
                      <input matInput [formControl]="emailForm" (keyup)="createNewHrRep($event)">
                    </mat-form-field>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-1">
                    <button mat-stroked-button class="cancel-hr-button w-100" (click)="clear()">Cancel</button>
                  </div>
                  <div class="col-1">
                    <button mat-stroked-button class="add-hr-button w-100" (click)="createNewHrRep()">Add</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
            <ng-container matColumnDef="{{column.prop}}" *ngFor="let column of columnsToDisplay">
              <th mat-header-cell *matHeaderCellDef> {{column.name}} </th>
              <td mat-cell *matCellDef="let element">
                {{element[column.prop] | translate}}
              </td>
            </ng-container>
            <ng-container matColumnDef="reset">
              <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
              <td mat-cell *matCellDef="let element" class="header-align-right">
                <div *ngIf="element.status.toString() === 'ACTIVE'">
                  <button mat-icon-button
                          (click)="disableHrRep(element)">
                    <mat-icon>lock_open</mat-icon>
                  </button>
                </div>
                <div *ngIf="element.status.toString() === 'PENDING'">
                  <button mat-icon-button
                          matTooltip="This user is not yet verified"
                          disabled>
                    <mat-icon>lock_open</mat-icon>
                  </button>
                </div>
                <div *ngIf="element.status.toString() === 'DISABLED'">
                  <button mat-icon-button
                          (click)="enableHrRep(element)">
                    <mat-icon>lock_person</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
              <td mat-cell *matCellDef="let element" class="header-align-right">
                <button mat-icon-button
                        (click)="deleteHrRep(element)">
                  <mat-icon>delete_forever</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToDisplayWithExpand;"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
