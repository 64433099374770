import {createReducer, on} from '@ngrx/store';
import {SessionState} from './index';
import * as SessionActions from './session.actions';
import {cloneDeep} from 'lodash';
import AvaeleUser from '../../domain/AvaeleUser';
import Company from '../../domain/Company';

export const sessionFeatureKey = 'session';

export const initialState: SessionState = {
  avaeleUser: null,
  hrReps: [],
  company: null,
};

export const jobListingReducer = createReducer(
  initialState,
  on(SessionActions.getHrRepsStart, (state: SessionState, params: {}): SessionState => {
    return state;
  }),
  on(SessionActions.getHrRepsSuccess, (state: SessionState, params: {hrReps: Array<any>}): SessionState => {
    const newState = cloneDeep(state);
    newState.hrReps = [...params.hrReps];
    return newState;
  }),
  on(SessionActions.getSignedInUserStart, (state: SessionState, params: {pk: string, sk: string, userType: string}): SessionState => {
    console.log('getSignedInUserStart', params);
    return state;
  }),
  on(SessionActions.getSignedInUserSuccess, (state: SessionState, params: {user: any}): SessionState => {
    console.log('getSignedInUserSuccess', params);
    const  newState = cloneDeep(state);
    newState.avaeleUser = params.user as AvaeleUser;
    console.log('getSignedInUserSuccess2222', newState);
    return newState;
  }),

  on(SessionActions.setSignedInData, (state: SessionState, params: {user: AvaeleUser, company: Company}): SessionState => {
    const  newState = cloneDeep(state);
    newState.user = params.user;
    newState.company = params.company;
    return newState;
  }),
);
