<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <h1 class="page-title">Console</h1>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-12">
      <div class="card w-100 mb-3">
        <div class="card-body py-4">
          <div class="container-fluid p-0">
            <div class="row">
              <div class="col-12">
                <app-selectable-card-group (selectedChange)="foo($event)">
                  <div class="container-fluid p-0">
                    <div class="row">
                      <div class="col-2">
                        <app-selectable-card [value]="'HR'" [routerLink]="'/console/HR'">
                          <h4 style="margin: 0">Human Resources</h4>
                        </app-selectable-card>
                      </div>
                      <div class="col-2">
                        <app-selectable-card [value]="'Employees'" [routerLink]="'/console/Employees'">
                          <h4 style="margin: 0">Employees</h4>
                        </app-selectable-card>
                      </div>
                      <div class="col-2">
                        <app-selectable-card [value]="'Account'" [routerLink]="'/console/Account'">
                          <h4 style="margin: 0">Account</h4>
                        </app-selectable-card>
                      </div>
                      <div class="col-2">
                        <app-selectable-card [value]="'Billing'" [routerLink]="'/console/Billing'">
                          <h4 style="margin: 0">Billing</h4>
                        </app-selectable-card>
                      </div>
                    </div>
                  </div>
                </app-selectable-card-group>
                <!--                <mat-card class="card w-100 mat-elevation-z0" [routerLink]="'/console/HR'">-->
                <!--                  <mat-card-title>-->
                <!--                    Human Resources-->
                <!--                  </mat-card-title>-->
                <!--                </mat-card>-->
                <!--                <div class="card w-100" [routerLink]="'/console/HR'">-->
                <!--                  <div class="card-body selection-card py-4">-->
                <!--                    <div class="card-title selection-title">Human Resources</div>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--              </div>-->
                <!--              <div class="col-2">-->
                <!--                <div class="card w-100" [routerLink]="'/console/Employees'">-->
                <!--                  <div class="card-body selection-card py-4" >-->
                <!--                    <div class="card-title selection-title">Employees</div>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--              </div>-->
                <!--              <div class="col-2">-->
                <!--                <div class="card w-100" [routerLink]="'/console/Account'">-->
                <!--                  <div class="card-body selection-card py-4">-->
                <!--                    <div class="card-title selection-title">Account</div>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--              </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <router-outlet>
        </router-outlet>
      </div>
    </div>
  </div>
