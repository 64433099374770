import {AfterViewInit, ChangeDetectionStrategy, Component, Inject, ViewChild} from '@angular/core';
import {lyl, StyleRenderer, ThemeRef, ThemeVariables, WithStyles} from '@alyle/ui';
import {LY_DIALOG_DATA, LyDialogRef} from '@alyle/ui/dialog';
import {STYLES as SLIDER_STYLES} from '@alyle/ui/slider';
import {ImgCropperConfig, ImgCropperErrorEvent, ImgCropperEvent, LyImageCropper, STYLES as CROPPER_STYLES} from '@alyle/ui/image-cropper';

import {faCrosshairs, faSearchMinus, faSearchPlus, faUndo} from '@fortawesome/free-solid-svg-icons';

const STYLES = (_theme: ThemeVariables, ref: ThemeRef) => {
  ref.renderStyleSheet(SLIDER_STYLES);
  ref.renderStyleSheet(CROPPER_STYLES);
  const slider = ref.selectorsOf(SLIDER_STYLES);
  const cropper = ref.selectorsOf(CROPPER_STYLES);

  return {
    root: lyl `{
      ${cropper.root} {
        max-width: 700px
        height: 700px
      }
    }`,
    sliderContainer: lyl `{
      position: relative
      ${slider.root} {
        width: 80%
        position: absolute
        left: 0
        right: 0
        margin: auto
        top: -32px
      }
    }`,
    slider: lyl `{
      padding: 1em
    }`
  };
};

@Component({
  templateUrl: './cropper-dialog.html',
  styleUrls: ['./cropper-dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    StyleRenderer
  ]
})
export class CropperDialog implements WithStyles, AfterViewInit {

  readonly classes = this.sRenderer.renderSheet(STYLES, 'root');
  ready: boolean;
  scale: number;
  minScale: number;
  @ViewChild(LyImageCropper, { static: true }) cropper: LyImageCropper;
  myConfig: ImgCropperConfig = {
    width: 350,
    height: 225,
    // type: 'image/png',
    keepAspectRatio: true,
    responsiveArea: true,
    output: {
      width: 350,
      height: 225
    },
    resizableArea: false
  };

  zoomInIcon = faSearchPlus;
  zoomOutIcon = faSearchMinus;
  rotateCCW = faUndo;
  center = faCrosshairs;

  constructor(
    @Inject(LY_DIALOG_DATA) private event: Event,
    readonly sRenderer: StyleRenderer,
    public dialogRef: LyDialogRef
  ) { }

  ngAfterViewInit() {
    // Load image when dialog animation has finished
    this.dialogRef.afterOpened.subscribe(() => {
      this.cropper.selectInputEvent(this.event);
    });
  }

  onCropped(e: ImgCropperEvent) {
    // console.log('cropped img: ', e);
  }
  onLoaded(e: ImgCropperEvent) {
    // console.log('img loaded', e);
  }
  onError(e: ImgCropperErrorEvent) {
    console.warn(`'${e.name}' is not a valid image`, e);
    // Close the dialog if it fails
    this.dialogRef.close();
  }

}
