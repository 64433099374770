export default class AnalyticsLocationFilter {
  lat : number;
  lng : number;
  radius : number;

  constructor(lat : number, lng : number, radius : number) {
    this.lat = lat;
    this.lng = lng;
    this.radius = radius;
  }
}
