import {Component, OnInit} from '@angular/core';
import {Auth} from 'aws-amplify';
import {Store} from '@ngrx/store';
import {SessionState} from '../../store/session-store';
import {ActivatedRoute, Router} from '@angular/router';
import {getSignedInUserStart} from '../../store/session-store/session.actions';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DialogService} from '../../services/dialog.service';

@Component({
  selector: 'app-forgot-password-start',
  templateUrl: './forgot-password-start.component.html',
  styleUrls: ['./forgot-password-start.component.scss']
})
export class ForgotPasswordStartComponent implements OnInit {
  emailAddress: string;
  emailForm: FormControl = new FormControl('', [Validators.required, Validators.pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')]);
  formGroup: FormGroup;

  constructor(private router: Router,
              private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      Email: this.emailForm,
    });
  }

  forgotPassword($event?: any) {
    if ($event == null || $event.keyCode == 13) {
      if (this.emailAddress && this.formGroup.valid) {
        Auth.forgotPassword(this.emailAddress).then(
          r => {
            this.router.navigate(['forgotPasswordComplete'], {queryParams: {userName: btoa(this.emailAddress)}});
          },
          err => this.dialogService.error('Error', 'Must enter valid email address'));
      } else {
        this.dialogService.error('Error', 'Must enter valid email address');
      }
    }
  }
}
