<!--<div class="wrapper">-->
<!--  <div class="d-flex sidebar" [style.width]="getSideBarWidth()">-->
<!--    <ul class="sidebar-nav w-100 p-3">-->
<!--      <li [routerLink]="link.fragment" class="sidebar-item d-flex justify-content-between" *ngFor="let link of links" [routerLinkActive]="'active-link'">-->
<!--        <a>{{ link.title }}</a>-->
<!--        <span *ngIf="link.premium" class="badge badge-sidebar-primary">Premium</span>-->
<!--      </li>-->
<!--    </ul>-->
<!--  </div>-->
<!--  <div id="main" class="main" [style]="getMainWidth()">-->
<!--    <nav class="navbar navbar-expand-lg navbar-light bg-transparent">-->
<!--      <div class="container-fluid">-->
<!--        <button id="menuButton" (click)="toggleSideBar()" class="btn"-->
<!--                type="button"-->
<!--                aria-controls="navbarToggleExternalContent"-->
<!--                aria-expanded="false" aria-label="Toggle navigation">-->
<!--          <span class="navbar-toggler-icon hamburger"></span>-->
<!--        </button>-->
<!--      </div>-->
<!--    </nav>-->
<!--    <div class="content w-100 container-fluid">-->
<!--      <div class="row">-->
<!--        <div class="col-12">-->
<!--          <router-outlet>-->
<!--          </router-outlet>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<mat-drawer-container class="homepage-sidenav-container sidenav-background" autosize>
  <mat-drawer #drawer class="homepage-sidenav sidenav-background" mode="side" opened>
    <div class="sidenav-div-container">
      <mat-nav-list>
        <ul class="w-100 p-3">
          <li [routerLink]="link.fragment" class="sidebar-item d-flex justify-content-between"
              *ngFor="let link of links"
              [routerLinkActive]="'active-link'">
            <a>{{ link.title }}</a>
            <span *ngIf="link.premium" class="badge badge-sidebar-primary">Premium</span>
          </li>
        </ul>
      </mat-nav-list>

      <div class="w-100 p-3">
        <button mat-stroked-button class="logout-button w-100" (click)="logout()">Logout</button>
      </div>
    </div>
  </mat-drawer>
  <div class="content w-100 container-fluid">
    <div class="row">
      <div class="col-12">
        <button mat-icon-button class="white-icon" (click)="drawer.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <router-outlet>
        </router-outlet>
      </div>
    </div>
  </div>
</mat-drawer-container>
