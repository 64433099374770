import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Auth} from 'aws-amplify';
import {getSignedInUserStart} from '../../store/session-store/session.actions';
import {Store} from '@ngrx/store';
import {SessionState} from '../../store/session-store';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AdminService} from '../../services/admin.service';
import {AvaeleUserStatus} from '../../domain/AvaeleUserStatus';
import {DialogService} from '../../services/dialog.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  password: string;
  passwordConfirm: string;
  email: string;
  tmpPassword: string;

  tmpPasswordForm: FormControl = new FormControl('', [Validators.required]);
  passwordForm: FormControl = new FormControl('', [Validators.required]);
  passwordConfirmForm: FormControl = new FormControl('', [Validators.required]);
  formGroup: FormGroup;
  hideTmpPassword: boolean = true;
  hidePassword: boolean = true;
  hidePasswordConfirm: boolean = true;
  constructor(private router: Router,
              private route: ActivatedRoute,
              public sessionStore: Store<SessionState>,
              public adminService: AdminService,
              public dialogService: DialogService) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe((params) => {
      this.email = atob(params.userName);
    });

    this.formGroup = new FormGroup({
      TmpPassword: this.tmpPasswordForm,
      Password: this.passwordForm,
      passwordConfirm: this.passwordConfirmForm
    });
  }

  changePassword($event?: any) {
    if ($event == null || $event.keyCode == 13) {
      if (this.password === this.passwordConfirm) {
        try {
          Auth.signIn(this.email, this.tmpPassword).then(user => {
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
              Auth.completeNewPassword(user, this.password).then(() => {
                Auth.currentAuthenticatedUser().then((currentAuthUser) => {
                  const pk = currentAuthUser.attributes['custom:company_id'];
                  const userType = currentAuthUser.attributes['custom:user_type'];
                  const sk = currentAuthUser.attributes['email'];
                  this.adminService.updateUserStatus(pk, sk, AvaeleUserStatus.ACTIVE).subscribe((result) => {
                    this.sessionStore.dispatch(getSignedInUserStart({pk, sk, userType}));
                    this.router.navigate(['/']);
                  });
                });
              });
            }
          });
        } catch (e) {
          this.dialogService.error('Error', 'Could not change password');
          console.log('error changing password:', e);
        }
      } else {
        console.log('Passwords must match!');
      }
    }
  }
}
