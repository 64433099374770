<div ly-dialog-content [lyPx]="0" class="wrapper">

  <div *ngIf="ready" style="height: 72px; padding: 16px; display: flex; flex-direction: row; justify-content: center">
    <button class="cropper-icon" (click)="cropper.zoomIn()" ly-button appearance="icon" lyTooltip="Zoom In"><fa-icon [icon]=zoomInIcon></fa-icon></button>
    <button class="cropper-icon" (click)="cropper.zoomOut()" ly-button appearance="icon" lyTooltip="Zoom Out"><fa-icon [icon]=zoomOutIcon></fa-icon></button>
    <button class="cropper-icon" (click)="cropper.center()" ly-button appearance="icon" lyTooltip="Center"><fa-icon [icon]="center"></fa-icon></button>
    <button class="cropper-icon" (click)="cropper.rotate(-90)" ly-button appearance="icon" lyTooltip="Rotate"><fa-icon [icon]=rotateCCW></fa-icon></button>
    <button (click)="cropper.fit()" ly-button style="font-size: 24px" >Fit</button>
    <button (click)="cropper.fitToScreen()" ly-button style="font-size: 24px" >Fit to screen</button>
    <button (click)="cropper.setScale(1)" ly-button style="font-size: 24px" >1:1</button>
  </div>

  <ly-img-cropper
    [config]="myConfig"
    [(scale)]="scale"
    (ready)="ready = true"
    (cleaned)="ready = false"
    (minScale)="minScale = $event"
    (cropped)="onCropped($event)"
    (loaded)="onLoaded($event)"
    (error)="onError($event)"
  >
    <span>Drag and drop image</span>
  </ly-img-cropper>

  <!--TODO: not working, but is it important?-->
  <!--<div *ngIf="ready" [className]="classes.sliderContainer">
    <div [class]="classes.slider">
      <ly-slider
        [thumbVisible]="false"
        [min]="minScale"
        [max]="1"
        [(ngModel)]="scale"
        (input)="scale = $event.value"
        step="0.000001"></ly-slider>
    </div>
  </div>-->

</div>

<div class="wrapper" container *ngIf="ready" [lyJustifyContent]="'flex-end'" ly-dialog-actions>
  <button class="btn btn-outline-primary cropper-button" (click)="dialogRef.close()">CANCEL</button>
  <button class="btn btn-primary cropper-button" (click)="dialogRef.close(cropper.crop())">DONE</button>
</div>
