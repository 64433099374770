import {Component, OnInit} from '@angular/core';
import Employee from '../domain/employee';
import {faFacebook, faInstagram, faLinkedin, faTwitter} from '@fortawesome/free-brands-svg-icons';
import {faGlobe, faHome} from '@fortawesome/free-solid-svg-icons';
import {Occupation} from '../domain/Occupation';

@Component({
  selector: 'app-employee-page',
  templateUrl: './employee-page.component.html',
  styleUrls: ['./employee-page.component.scss']
})
export class EmployeePageComponent implements OnInit {

  employee: Employee = new Employee();
  faTwitter = faTwitter;
  faFaceBook = faFacebook;
  faInstagram = faInstagram;
  faLinkedin = faLinkedin;
  faHome = faHome;
  faGlobe = faGlobe;

  constructor() {
    this.employee.firstName = 'rem';
    this.employee.lastName = 'levy';
    this.employee.occupation = Occupation.WELDER;
    this.employee.email = 'rem.levy@avaele.com';
    this.employee.id = '1';
    this.employee.experienceInYears = 3;
    this.employee.industry = 'oil';
    this.employee.location = {latitude: 44, longitude: 33};
    this.employee.avatarUrl = 'https://i.imgur.com/I23eIi1.png';
    this.employee.facebookUrl = 'https://i.imgur.com/I23eIi1.png';
    this.employee.instagramUrl = 'https://i.imgur.com/I23eIi1.png';
    this.employee.linkedlnUrl = 'https://i.imgur.com/I23eIi1.png';
    this.employee.twitterUrl = 'https://i.imgur.com/I23eIi1.png';
  }

  ngOnInit(): void {
  }

}
