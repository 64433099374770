import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromSessionReducer from './session.reducer';
import {EffectsModule} from '@ngrx/effects';
import SessionEffects from './session.effects';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(fromSessionReducer.sessionFeatureKey,  fromSessionReducer.jobListingReducer),
        EffectsModule.forFeature([SessionEffects])
    ],
    providers: []
})
export class SessionStoreModule {
}
