import {Component, OnDestroy, OnInit, Output, EventEmitter} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {getJobListings, JobListingState} from '../../store';
import {getJobListingsStart} from '../../store/job-listing-store/job-listings.actions';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import JobListing from '../../domain/JobListing';

@Component({
  selector: 'app-job-listing-selector',
  templateUrl: './job-listing-selector.component.html',
  styleUrls: ['./job-listing-selector.component.scss']
})
export class JobListingSelectorComponent implements OnInit, OnDestroy {

  private _selectedJobListing: any;
  private destroyed$ = new Subject<boolean>();
  existingJobListings: Array<JobListing> = [];

  @Output('selectedJobListingChange') eventEmitter: EventEmitter<JobListing> = new EventEmitter<JobListing>();

  constructor(public jobListingStore: Store<JobListingState>) {
    this.jobListingStore.dispatch(getJobListingsStart({companyId: 'ORG#123', hrRepId: ''}));

    this.jobListingStore
      .pipe(select(getJobListings))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((jobListings) => {
        this.existingJobListings = [...jobListings];
      });
  }

  get selectedJobListing(): any {
    return this._selectedJobListing;
  }

  set selectedJobListing(value: any) {
    this._selectedJobListing = value;
    this.eventEmitter.emit(value);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
