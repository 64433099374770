import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {CandidateEmployeesComponent} from './candidate-employees/candidate-employees.component';
import {AppRoutingModule} from './app-routing.module';
import {RouterModule} from '@angular/router';
import {AnalyticsComponent} from './analytics/analytics.component';
import {NgApexchartsModule} from 'ng-apexcharts';
import {RequestedCompensationChartComponent} from './analytics/requested-compensation-chart/requested-compensation-chart.component';
import {EmployeePageComponent} from './employee-page/employee-page.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CreateJobListingComponent} from './create-job-listing/create-job-listing.component';
import {CropperWithDialogComponent} from './cropper-with-dialog/cropper-with-dialog.component';
import {CropperDialog} from './cropper-with-dialog/cropper-dialog';
import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {LY_THEME, LY_THEME_GLOBAL_VARIABLES, LY_THEME_NAME, LyHammerGestureConfig, LyTheme2, StyleRenderer} from '@alyle/ui';
import {MinimaDark, MinimaLight} from '@alyle/ui/themes/minima';

import {color} from '@alyle/ui/color';
import {LyImageCropperModule} from '@alyle/ui/image-cropper';
import {LySliderModule} from '@alyle/ui/slider';
import {LyButtonModule} from '@alyle/ui/button';
import {LyIconModule} from '@alyle/ui/icon';
import {LyDialogModule} from '@alyle/ui/dialog';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LyTypographyModule} from '@alyle/ui/typography';
import {LyTooltipModule} from '@alyle/ui/tooltip';
import {CreateJobListingCardComponent} from './create-job-listing/create-job-listing-card/create-job-listing-card.component';
import {JobListingListCardComponent} from './create-job-listing/job-listing-list-card/job-listing-list-card.component';
import {RootStoreModule} from './store';
import {JobListingCardComponent} from './create-job-listing/job-listing-card/job-listing-card.component';
import {JobListingCommandBarComponent} from './create-job-listing/job-listing-command-bar/job-listing-command-bar.component';
import {AdminConsoleComponent} from './admin-console/admin-console.component';
import {SignInComponent} from './auth/sign-in/sign-in.component';
import {ForgotPasswordCompleteComponent} from './auth/forgot-password-complete/forgot-password-complete.component';
import {Amplify} from 'aws-amplify';
import {SignUpCodeComponent} from './auth/sign-up-code/sign-up-code.component';
import {AutoTabDirective} from './auth/auto-tab.directive';
import {AuthCodeInputComponent} from './auth/auth-code-input/auth-code-input.component';
import {OnlyIntegerDirective} from './auth/only-integer.directive';
import {CodeInputModule} from 'angular-code-input';
import {HomePageComponent} from './home-page/home-page.component';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {HrRepConsoleComponent} from './admin-console/hr-rep-console/hr-rep-console.component';
import {LocalTranslateLoader} from './services/local-translate-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {DemoMaterialModule} from './material-module';
import {SelectableCardComponent} from './misc/selectable-card/selectable-card.component';
import {SelectableCardGroupComponent} from './misc/selectable-card-group/selectable-card-group.component';
import {MAT_CHIPS_DEFAULT_OPTIONS} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {EmployeesConsoleComponent} from './admin-console/employees-console/employees-console.component';
import {AccountConsoleComponent} from './admin-console/account-console/account-console.component';
import {BillingConsoleComponent} from './admin-console/billing-console/billing-console.component';
import {OffersComponent} from './offers/offers.component';
import {JobListingSelectorComponent} from './misc/job-listing-selector/job-listing-selector.component';
import {ConfirmationDialogComponent} from './common/confirmation-dialog/confirmation-dialog.component';
import {ChangePasswordComponent} from './auth/change-password/change-password.component';
import {ForgotPasswordStartComponent} from './auth/forgot-password-start/forgot-password-start.component';
import {ErrorDialogComponent} from './common/error-dialog/error-dialog.component';
import {PopulationRankedChartComponent} from './analytics/population-ranked-chart/population-ranked-chart.component';
import {AcceptedCompensationChartComponent} from './analytics/accepted-compensation-chart/accepted-compensation-chart.component';

export class GlobalVariables {
  testVal = color(0x00bcd4);
  Quepal = {
    default: `linear-gradient(135deg,#11998e 0%,#38ef7d 100%)`,
    contrast: color(0xffffff),
    shadow: color(0x11998e)
  };
  SublimeLight = {
    default: `linear-gradient(135deg,#FC5C7D 0%,#6A82FB 100%)`,
    contrast: color(0xffffff),
    shadow: color(0xB36FBC)
  };
  Amber = {
    default: color(0xffc107),
    contrast: color(0, 0, 0, 0.87)
  };
}

function translateLoaderFactory() {
  return new LocalTranslateLoader();
}

Amplify.configure({
  Auth: {

    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

    // REQUIRED - Amazon Cognito Region
    region: 'us-east-2',

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'XX-XXXX-X',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-east-2_fZtm68jBR',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '3vo1n07qmqj9ucnk0bh08b2f8l',

    // // OPTIONAL - Configuration for cookie storage
    // // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //   domain: 'avaele.com',
    //   // OPTIONAL - Cookie path
    //   path: '/',
    //   // OPTIONAL - Cookie expiration in days
    //   expires: 365,
    //   // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //   sameSite: 'strict',
    //   // OPTIONAL - Cookie secure flag
    //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //   secure: true
    // },

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_SRP_AUTH',
  }
});

@NgModule({
  declarations: [
    AppComponent,
    CandidateEmployeesComponent,
    AnalyticsComponent,
    RequestedCompensationChartComponent,
    AcceptedCompensationChartComponent,
    EmployeePageComponent,
    CreateJobListingComponent,
    CropperWithDialogComponent,
    CropperDialog,
    CreateJobListingCardComponent,
    JobListingListCardComponent,
    JobListingCardComponent,
    JobListingCommandBarComponent,
    AdminConsoleComponent,
    SignInComponent,
    ChangePasswordComponent,
    SignUpCodeComponent,
    AutoTabDirective,
    AuthCodeInputComponent,
    OnlyIntegerDirective,
    OnlyIntegerDirective,
    HomePageComponent,
    HrRepConsoleComponent,
    SelectableCardComponent,
    SelectableCardGroupComponent,
    EmployeesConsoleComponent,
    AccountConsoleComponent,
    BillingConsoleComponent,
    OffersComponent,
    JobListingSelectorComponent,
    ConfirmationDialogComponent,
    ForgotPasswordCompleteComponent,
    ForgotPasswordStartComponent,
    ErrorDialogComponent,
    PopulationRankedChartComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    NgApexchartsModule,
    FontAwesomeModule,
    NgxDatatableModule,
    NgSelectModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    LyImageCropperModule,
    LySliderModule,
    LyButtonModule,
    LyIconModule,
    LyDialogModule,
    HammerModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LyTypographyModule,
    LyTooltipModule,
    RootStoreModule,
    CodeInputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory
      }
    }),
    DemoMaterialModule,
  ],
  providers: [
    [LyTheme2],
    [StyleRenderer],
    {provide: LY_THEME_NAME, useValue: 'minima-dark'},
    {
      provide: LY_THEME,
      useClass: MinimaLight,
      multi: true
    },
    {
      provide: LY_THEME,
      useClass: MinimaDark,
      multi: true
    },
    {
      provide: LY_THEME_GLOBAL_VARIABLES,
      useClass: GlobalVariables
    }, // global variables
    // Gestures
    {provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig},
    {
      provide: HTTP_INTERCEPTORS, useExisting: AuthInterceptor, multi: true
    },
    AuthInterceptor,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [ENTER, COMMA]
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
