import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {selectHrReps, SessionState} from '../../store/session-store';
import {getHrRepsStart} from '../../store/session-store/session.actions';
import {takeUntil} from 'rxjs/operators';
import {Auth} from 'aws-amplify';
import {AdminService} from '../../services/admin.service';
import HrRep from '../../domain/HrRep';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DialogService} from '../../services/dialog.service';
import {AvaeleUserStatus} from '../../domain/AvaeleUserStatus';

@Component({
  selector: 'app-hr-rep-console',
  templateUrl: './hr-rep-console.component.html',
  styleUrls: ['./hr-rep-console.component.scss'],
})
export class HrRepConsoleComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<boolean>();

  addUserFlag = false;
  email: string;
  name: string;

  columnsToDisplay = [
    {prop: 'name', name: 'Name'},
    {prop: 'email', name: 'Email'},
  ];
  columnsToDisplayWithExpand = [...this.columnsToDisplay.map(column => column.prop), 'reset', 'delete'];

  dataSource: Array<HrRep> = [];

  formGroup: FormGroup;
  emailForm: FormControl = new FormControl('', [Validators.required, Validators.pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')]);
  nameForm: FormControl = new FormControl('', [Validators.required]);

  constructor(public sessionStateStore: Store<SessionState>, private adminService: AdminService,
              private dialogService: DialogService) {

    this.sessionStateStore.dispatch(getHrRepsStart({foo: {}}));

    this.sessionStateStore
      .pipe(select(selectHrReps))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((hrReps) => {
        this.dataSource = [...hrReps];
      });

    this.formGroup = new FormGroup({
      Name: this.nameForm,
      Email: this.emailForm
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  toggleAddUser() {
    this.addUserFlag = !this.addUserFlag;
  }

  clear() {
    this.formGroup.reset();
    this.sessionStateStore.dispatch(getHrRepsStart({foo: {}}));
    this.toggleAddUser();
  }

  //TODO move to a store effect, maybe consider what happens in a hang/crash
  async createNewHrRep($event?: any): Promise<void> {
    if ($event == null || $event.keyCode == 13) {
      const user = await Auth.currentAuthenticatedUser();
      const hrRep: HrRep = new HrRep(this.emailForm.value, this.emailForm.value, this.nameForm.value, user.attributes['custom:company_id'], AvaeleUserStatus.PENDING);
      this.adminService.createHrRep(hrRep).subscribe(
        data => {
          this.clear();
          this.addUserFlag = false;
        },
        err => {
          this.dialogService.error('Error', 'Could not create user, make sure you have entered a valid name and email');
        }
      );
    }
  }

  //TODO move to a store effect, maybe consider what happens in a hang/crash
  async deleteHrRep(element): Promise<void> {
    this.dialogService.confirm('Please confirm..', 'Do you really want to delete this user?')
      .then((confirmed) => {
        if (confirmed) {
          this.adminService.deleteHrRep(element.companyID, element.email).subscribe(
            result => {
              this.clear();
              this.addUserFlag = false;
            },
            err => {
              this.dialogService.error('Error', 'Could not delete user');
            }
          );
        }
      }).catch(() => console.log('User dismissed the dialog'));
  }

  async enableHrRep(element) {
    this.dialogService.confirm('Please confirm..', 'Do you really want to enable this users?')
      .then((confirmed) => {
        if (confirmed) {
          this.adminService.enableUser(element.companyID, element.email).subscribe(
            result => {
              this.clear();
              this.addUserFlag = false;
            },
            err => {
              this.dialogService.error('Error', 'Could not enable user');
            }
          );
        }
      }).catch(() => console.log('User dismissed the dialog'));
  }

  async disableHrRep(element) {
    this.dialogService.confirm('Please confirm..', 'Do you really want to disable this users?')
      .then((confirmed) => {
        if (confirmed) {
          this.adminService.disableUser(element.companyID, element.email).subscribe(
            result => {
              this.clear();
              this.addUserFlag = false;
            },
            err => {
              this.dialogService.error('Error', 'Could not disable user');
            });
        }
      }).catch(() => console.log('User dismissed the dialog'));
  }
}
