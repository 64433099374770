import {AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, forwardRef, HostListener, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-auth-code-input',
  templateUrl: './auth-code-input.component.html',
  styleUrls: ['./auth-code-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AuthCodeInputComponent),
      multi: true
    }
  ]
})
export class AuthCodeInputComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  @Output('codeCompleted') codeCompletedEmitter: EventEmitter<string> = new EventEmitter();
  private val: string;

  constructor() {
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: string): void {
    this.value = value;
  }

  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  set value(val) {
    this.val = val;
  }

  get value(): string {
    return this.val;
  }

  onCodeCompleted(): void {
    this.codeCompletedEmitter.emit(this.value);
  }

  onCodeChanged($event: string): void {
    if ($event !== undefined && this.value !== $event) {
      this.value = $event;
      this.onChange($event);
      this.onTouch($event);
    }
  }
}
