import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import HrRep from '../domain/HrRep';
import AvaeleUser from '../domain/AvaeleUser';
import {environment} from '../../environments/environment';
import {AvaeleUserStatus} from '../domain/AvaeleUserStatus';
import {Analytics} from 'aws-amplify';
import AnalyticRequest from '../domain/analytics/AnalyticRequest';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  // TODO: turn restEndPoint into environment variable
  // private restEndPoint = environment.backend + 'admin/';
  private restEndPoint = environment.dev_backend + 'admin/';

  constructor(private http: HttpClient) {
  }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,POST,PATCH,DELETE,PUT,OPTIONS',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, content-type',
    })
  };

  getHrReps(id: string): Observable<Array<any>>{
    return this.http.get<Array<any>>(this.restEndPoint + 'getAllHr/' + id, this.httpOptions).pipe(
      tap(
        (val) => {
          console.log('GET call successful value returned in body', val);
        },
        response => {
          console.log('GET call in error', response);
        },
        () => {
          console.log('The GET observable is now completed.');
        })
    );
  }

  createHrRep(hrRep: HrRep): Observable<any> {
    return this.http.post(this.restEndPoint + 'createHrRep', hrRep, this.httpOptions);
  }

  updateUserStatus(pk: string, sk: string, status: AvaeleUserStatus): Observable<any> {
    return this.http.put(this.restEndPoint + 'updateHrRepStatus/' + pk + '/' + sk + '/' + status.toString(), null, this.httpOptions);
  }

  enableUser(pk: string, sk: string): Observable<any> {
    return this.http.put(this.restEndPoint + 'enableHrRep/' + pk + '/' + sk, null, this.httpOptions);
  }

  disableUser(pk: string, sk: string): Observable<any> {
    return this.http.put(this.restEndPoint + 'disableHrRep/' + pk + '/' + sk, null, this.httpOptions);
  }

  getUserFromDynamo(pk: string, sk: string, userType: string): Observable<any> {
    console.log('getUserFromDynamo', pk, userType, sk);
    return this.http.get(this.restEndPoint + 'getCorporateUser/' + pk + '/' + userType + '/' + sk).pipe(
      tap(
        (val) => {
          console.log('GET call successful value returned in body', val);
        },
        response => {
          console.log('GET call in error', response);
        },
        () => {
          console.log('The GET observable is now completed.');
        })
    );
  }

  deleteHrRep(pk: string, sk: string) {
    return this.http.delete(this.restEndPoint + 'deleteHrRep/' + pk + '/' + sk, this.httpOptions);
  }

  resetHrRepPassword(sk: string) {
    return this.http.patch(this.restEndPoint + 'resetHrRepPassword/' + sk, this.httpOptions);
  }
  getSignedInDetails(): Observable<any> {
    return this.http.get(this.restEndPoint + 'getSignedInData', this.httpOptions);
  }
}
