<div class="d-flex container-fluid p-0 w-100">
  <div class="row d-flex flex-grow-1">
    <div class="d-flex col-2 flex-grow-1">
      <div class="card w-100">
        <div class="card-body py-4">
          <div ngbNav #nav="ngbNav" class="nav-pills" orientation="vertical">
            <ng-container *ngFor="let chart of analyticCharts">
              <a  class="nav-item nav-link mb-2 mt-2"
                  [ngClass]="isCurrentChart(chart.urlName) ? 'active' : ''"
                  id="nav-home-tab"
                  data-toggle="tab"
                  [routerLink]="chart.urlName"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true">{{chart.analyticChart}}
              </a>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex col-10 flex-grow-1">
      <div class="w-100">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>


<!--<div class="tab-content" id="nav-tabContent">-->
<!--  <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">-->
<!--    <div class="card card-bg w-100">-->
<!--      <div class="card-body py-4">-->
<!--        <router-outlet></router-outlet>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

