import {TranslateLoader} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';

export class LocalTranslateLoader implements TranslateLoader {
  static translations = {
    PIPE_FITTER: 'Pipe Fitter',
    WELDER: 'Welder',
    // Experience
    LESS_THAN_ONE_YEAR: ' < 1 Year',
    BETWEEN_ONE_AND_FIVE_YEARS: '1 - 5 Years',
    BETWEEN_FIVE_AND_TEN_YEARS: ' 5 - 10 Years',
    MORE_THAN_TEN_YEARS: '10+ Years',
    // JobListingStatus
    ACTIVE: 'Active',
    SCHEDULED: 'Scheduled',
    CANCELLED: 'Cancelled',
    TERMINATED: 'Terminated',
    DELETED: 'Deleted',
    // JobType
    FULL_TIME: 'Full Time',
    PART_TIME: 'Part Time',
    CONTRACT: 'Contract',
    // Shift
    DAYS: 'Day Time',
    AFTERNOONS: 'Afternoon',
    NIGHTS: 'Night',
    OTHER: 'Other',
    NL: 'Newfoundland and Labrador',
    PE: 'Prince Edward Island',
    NS: 'Nova Scotia',
    NB: 'New Brunswick',
    QC: 'Quebec',
    ON: 'Ontario',
    MB: 'Manitoba',
    SK: 'Saskatchewan',
    AB: 'Alberta',
    BC: 'British Columbia',
    YT: 'Yukon',
    NT: 'Northwest Territories',
    NU: 'Nunavut',
  };

  constructor() {
  }

  getTranslation(lang: string): Observable<any> {
    return of(LocalTranslateLoader.translations);
  }
}
