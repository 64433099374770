import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Auth} from 'aws-amplify';
import {Store} from '@ngrx/store';
import {SessionState} from 'http2';
import {AdminService} from '../services/admin.service';
import HrRep from '../domain/HrRep';
import Company from '../domain/Company';
import {setSignedInData} from '../store/session-store/session.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, public sessionStore: Store<SessionState>, public adminService: AdminService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    return new Promise(async (resolve, reject) => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        this.adminService.getSignedInDetails().toPromise().then((responseJson) => {
          const user: HrRep = new HrRep(
            responseJson['user']['id'],
            responseJson['user']['email'],
            responseJson['user']['name'],
            responseJson['user']['companyID'],
            responseJson['user']['status']
          );

          const company: Company = new Company(
            responseJson['company']['id'],
            responseJson['company']['sk'],
            responseJson['company']['name'],
            responseJson['company']['imageUrl'],
            responseJson['company']['websiteUrl']
          );
          this.sessionStore.dispatch(setSignedInData({user, company}));
        });

        resolve(true);
      } catch (e) {
        this.router.navigate(['signin']);
        reject(e);
      }
    });
  }
}
