import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexPlotOptions, ApexXAxis, ChartComponent} from 'ng-apexcharts';
import {AnalyticService} from '../../services/analytics.service';
import AnalyticsLocationFilter from '../../domain/analytics/AnalyticsLocationFilter';
import {Province} from '../../domain/analytics/Province';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Shift} from '../../domain/Shift';
import {Experience} from '../../domain/Experience';
import {JobType} from '../../domain/JobType';
import {Occupation} from '../../domain/Occupation';
import {formatDate} from '@angular/common';
import {LocationType} from '../../domain/LocationType';
import {PlaceSearchResult} from '../analytics.component';
import RankedChartAnalyticRequest from '../../domain/analytics/RankedChartAnalyticRequest';
import TwoAxisCategoryChart from '../../domain/TwoAxisCategoryChart';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
};

@Component({
  selector: 'app-population-ranked-chart',
  templateUrl: './population-ranked-chart.component.html',
  styleUrls: ['./population-ranked-chart.component.scss']
})
export class PopulationRankedChartComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(ChartComponent) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  provinceCityAggregate = [];
  selectedExperienceForm = new FormControl(new Array<Experience>(), []);
  startDate = new FormControl('', []);
  endDate = new FormControl('', []);
  selectedCityForm = new FormControl(new Array<string>(), []);
  selectedProvincesForm = new FormControl('', []);
  selectedShiftForm = new FormControl(new Array<Shift>(), []);
  selectedOccupationForm = new FormControl(new Array<Occupation>(), []);
  selectedRadiusForm = new FormControl(new Array<Number>(), []);
  selectedLocationType = new FormControl(new Array<LocationType>(), [Validators.required]);

  location = new FormControl('', []);

  occupations = [];
  shifts = [];
  experiences = [];
  radiuses = [];
  locationTypes = [];
  jobTypes = [];
  provinces = [];

  formGroup: FormGroup;
  isCollapsed = false;
  private _queryDone = false;

  @ViewChild('inputField')
  inputField!: ElementRef;

  @Input() placeholder = 'Enter address...';

  @Output() placeChanged = new EventEmitter<PlaceSearchResult>();

  autocomplete: google.maps.places.Autocomplete | undefined;

  listener: any;

  constructor(private analyticService: AnalyticService, @Inject(LOCALE_ID) private locale: string, private ngZone: NgZone) {
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      selectedExperienceForm: this.selectedExperienceForm,
      startDate: this.startDate,
      endDate: this.endDate,
      selectedCityForm: this.selectedCityForm,
      selectedProvincecForm: this.selectedProvincesForm,
      selectedShiftForm: this.selectedShiftForm,
      selectedOccupationForm: this.selectedOccupationForm,
      location: this.location,
      selectedRadiusForm: this.selectedRadiusForm,
      selectedLocationType: this.selectedLocationType
    });

    this.shifts = Object.keys(Shift).filter(x => !(parseInt(x) >= 0));
    this.experiences = Object.keys(Experience).filter(x => !(parseInt(x) >= 0));
    this.jobTypes = Object.keys(JobType).filter(x => !(parseInt(x) >= 0));
    this.occupations = Object.keys(Occupation).filter(x => !(parseInt(x) >= 0));
    this.provinces = Object.keys(Province).filter(x => !(parseInt(x) >= 0));
    this.radiuses = [10, 20, 50, 100, 200, 500,10000];
    this.locationTypes = Object.keys(LocationType).filter(x => !(parseInt(x) >= 0));

    this.chartOptions = {
      series: [],
      chart: {
        type: 'bar',
        height: 425,
        foreColor: 'whitesmoke'
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      dataLabels: {
        enabled: true
      },
      xaxis: {
        type: 'numeric',
      }
    };
  }

  ngAfterViewInit(): void {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.inputField.nativeElement,
      {
        types: ['locality', 'administrative_area_level_3'],
        componentRestrictions: {'country': ['CA']},
        fields: ['geometry']
      }
    );

    this.autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place = this.autocomplete?.getPlace();
        const result: PlaceSearchResult = new PlaceSearchResult(this.inputField.nativeElement.value, place?.geometry?.location);

        this.placeChanged.emit(result);
        this.location.setValue(result);

        if (this.selectedRadiusForm.value.length == 0) {
          this.selectedRadiusForm.setValue(100);
        }

        if (this.selectedLocationType.value.length == 0) {
          this.selectedLocationType.setValue(LocationType.WORK);
        }
      });
    });
  }


  query() {
    let analyticsQueryRequest = new RankedChartAnalyticRequest(
      this.startDate.value != '' ? formatDate(this.startDate.value, 'yyyy-MM-dd', this.locale) : '',
      this.endDate.value != '' ? formatDate(this.endDate.value, 'yyyy-MM-dd', this.locale) : '',
      this.selectedExperienceForm.value,
      this.selectedOccupationForm.value,
      this.selectedShiftForm.value,
      this.location.value ? new AnalyticsLocationFilter(this.location.value.latlng.lat(),
        this.location.value.latlng.lng(),
        this.selectedRadiusForm.value) : null,
      this.selectedLocationType.value.length > 0 ? this.selectedLocationType.value  : null,
      10,
      false,
      false
    );

    this.analyticService.getUserRankings(analyticsQueryRequest).subscribe(compGraph => {
      const barChart = compGraph as TwoAxisCategoryChart;
      const data = []
      let maxVal = 0;
      for (let i = 0; i < barChart.labels.length; i++) {
        maxVal = Math.max(barChart.values[i], maxVal)
        data.push({
          y: barChart.values[i],
          x: barChart.labels[i]
        })
      }
      let series = [{
        name: "",
        data: data
      }];
      this.chart.updateOptions({
        xaxis: {
          type: 'numeric',
          tickAmount : maxVal <= 10 ? maxVal : undefined  // something so dumb it circles back into genius
        }
      });
      this.chart.updateSeries(series);

    });
  }

  ngOnDestroy(): void {
    if (this.autocomplete) {
      google.maps.event.clearInstanceListeners(this.autocomplete);
    }
  }
}
