import {Shift} from './Shift';
import {Occupation} from './Occupation';
import {JobListingStatus} from './JobListingStatus';
import Address from './Address';
import {Experience} from './Experience';
import {JobType} from './JobType';
import {JobListingQuestion} from "./JobListingQuestion";

export default class JobListing {

  jobListingId: string;
  imageUrl: string;
  companyId: string;
  companyName: string;
  occupation: Occupation;
  description: string;
  minExperience: Experience;
  ttl: string;
  employeeStartDate: string;
  jobListingStartDate: string;
  jobListingEndDate: string;
  period: string;
  address: Address;
  numHoursPerWeek: number;
  shift: Shift;
  jobListingState: JobListingStatus;
  jobType: JobType;
  numOpenings: number;
  qualCertsList: Array<string> = [];
  questions: Array<JobListingQuestion> = [];

  constructor(id?: string,
              imageUrl?: string,
              companyId?: string,
              companyName?: string,
              occupation?: Occupation,
              description?: string,
              minExperience?: Experience,
              ttl?: string,
              employeeStartDate?: string,
              jobListingStartDate?: string,
              jobListingEndDate?: string,
              period?: string,
              address?: Address,
              numHoursPerWeek?: number,
              shift?: Shift,
              jobListingState?: JobListingStatus,
              jobType?: JobType,
              numOpenings?: number,
              qualCertsList: Array<string> = [],
              questions: Array<JobListingQuestion> = []) {
    this.questions = questions;
    //TODO: remove placeholder values
    this.jobListingId = id || 'JL#4';
    this.imageUrl = imageUrl || '';
    this.companyId = companyId || 'ORG#123';
    this.companyName = companyName || 'Shell';
    this.occupation = occupation || Occupation.PIPE_FITTER;
    this.description = description || '';
    this.minExperience = minExperience || Experience.LESS_THAN_ONE_YEAR;
    this.ttl = ttl || '';
    this.employeeStartDate = employeeStartDate || '';
    this.jobListingStartDate = jobListingStartDate || '';
    this.jobListingEndDate = jobListingEndDate || '';
    this.period = period || '';
    this.address = address || new Address();
    this.numHoursPerWeek = numHoursPerWeek || 1;
    this.shift = shift || Shift.DAYS;
    this.jobListingState = jobListingState || JobListingStatus.ACTIVE;
    this.jobType = jobType || JobType.FULL_TIME;
    this.numOpenings = numOpenings || 1;
    this.qualCertsList = qualCertsList || [];
  }
}
