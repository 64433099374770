<div class="container-fluid" *ngIf="jobListing">
  <div class="row mb-3">
    <div class="col-12">
      <app-job-listing-command-bar>
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-4">
              <button class="btn btn-outline-success w-100" (click)="createNew()">Create New</button>
            </div>
            <div class="col-4">
              <button class="btn btn-outline-info w-100" (click)="copy()">Copy</button>
            </div>
            <div class="col-4">
              <button class="btn btn-outline-danger w-100">Terminate</button>
            </div>
          </div>
        </div>
      </app-job-listing-command-bar>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <table class="table table-striped mb-0">
          <tbody>
          <tr>
            <td>Status</td>
            <td>{{jobListing.jobListingState | translate}}</td>
          </tr>
          <tr>
            <td>Occupation</td>
            <td>{{jobListing.occupation | translate}}</td>
          </tr>
          <tr>
            <td>Experience</td>
            <td>{{jobListing.minExperience | translate}}</td>
          </tr>
          <tr>
            <td>Job Type</td>
            <td>{{jobListing.jobType ? jobListing.jobType : 'Full Time' | translate}}</td>
          </tr>
          <tr>
            <td>Shift</td>
            <td>{{jobListing.shift}}</td>
          </tr>
          <tr>
            <td>Start Date</td>
            <td>{{jobListing.employeeStartDate ? (jobListing.employeeStartDate | date) : '10/05/2021' | translate}}</td>
          </tr>
          <tr>
            <td>End Date</td>
            <td>{{jobListing['endDate'] ? jobListing['endDate'] : '10/05/2022' | translate}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
