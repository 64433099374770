<div class="container-fluid w-50 card p-5">
  <div class="row mb-5">
    <div class="col-12 d-flex justify-content-center align-content-center align-items-center">
      <h1 class="page-title">VERIFICATION CODE</h1>
    </div>
  </div>
  <div class="row mb-5">
    <div class="col-12">
      <app-auth-code-input (codeCompleted)="onCodeCompleted($event)"></app-auth-code-input>
    </div>
  </div>
</div>
