import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AppUser} from "../domain/AppUser";

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  // TODO: turn restEndPoint into environment variable
  // private restEndPoint = environment.backend + '';

  private restEndPoint = environment.dev_backend + '';

  constructor(private http: HttpClient) {
  }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,POST,PATCH,DELETE,PUT,OPTIONS',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, content-type',
    })
  };

  getEmployees() {
    return this.http.get<Array<AppUser>>(this.restEndPoint + 'jobListing/search', this.httpOptions);
  }
}
