  <div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
    <button mat-icon-button
            (click)="dismiss()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="modal-body">
    {{ message }}
  </div>
  <div class="modal-footer">
    <div>
      <button mat-stroked-button class="accept-button" (click)="accept()">{{ btnOkText }}</button>
    </div>
  </div>
