import {Experience} from '../Experience';
import {Occupation} from '../Occupation';
import {Shift} from '../Shift';
import AnalyticsLocationFilter from './AnalyticsLocationFilter';
import {LocationType} from '../LocationType';

export default class AnalyticRequest{
  startDate : string;
  endDate : string;
  experienceList: Array<Experience>;
  occupationList: Array<Occupation>;
  shiftList: Array<Shift>;
  locationFilter: AnalyticsLocationFilter;
  locationType: string;


  constructor(startDate: string, endDate: string, experienceList: Array<Experience>, occupationList: Array<Occupation>, shiftList: Array<Shift>, locationFilter: AnalyticsLocationFilter, locationType: LocationType) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.experienceList = experienceList;
    this.occupationList = occupationList;
    this.shiftList = shiftList;
    this.locationFilter = locationFilter;
    this.locationType  = locationType;
  }
}
